import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// react-responsive
import { useMediaQuery } from "react-responsive";

// styled components
import styled from "styled-components";

// Components
import HeaderComponent from "../components/HeaderComponent";
import SidebarComponent from "../components/SidebarComponent";
import GreenButton from "../components/button/GreenButton";
import FilterModal from "../components/FilterModal";
import SortModal from "../components/SortModal";
import Pagination from "../components/layout/Pagination";
import TableComponent from "../components/TableComponent";
import ModalComponent from "../components/ModalComponent";
import SpinnerComponent from "../components/SpinnerComponent";
import StatusComponent from "../components/StatusComponent";
import LoadingModalMobile from "../components/LoadingModalMobile";

// Styles
import "../styles/myBooking.css";

// sweetalert
import Swal from "sweetalert2";

// Images & Icons
import empty_session_1_icon from "../assets/icons/empty_session_1_icon.svg";
import sort_icon from "../assets/icons/sort_icon.svg";
import filter_icon from "../assets/icons/filter_icon.svg";

// lib
import font_size from "../lib/styling/font_size";

// store
import { SET_APPROVAL_MODAL_FALSE } from "../store/actions";

// Apis
import { getAllMentors } from "../apis/clientApis/mentors";
import {
  getAllUsersSessions,
  getDataBooking,
  updateOneSession,
} from "../apis/clientApis/sessions";
import { getAllTopics } from "../apis/topics";

// helpers
import { device } from "../helpers/device";
import fontSize from "../helpers/fontSize";

// utils
import { dateFormat } from "../utils/common";

const MyBooking = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [topics, setTopics] = useState([]);
  const [mentors, setMentors] = useState([]);
  const [data, setData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const [isFilterModalOpened, setFilterModalOpened] = useState(false);
  const [isSortModalOpened, setSortModalOpened] = useState(false);
  const [perPage, setPerPage] = useState(15);
  const [pageCount, setPageCount] = useState(0);
  const [scheduledDate, setScheduledDate] = useState("");
  const [itemOffset, setItemOffset] = useState(0);

  const approvalModal = useSelector((state) => state.approvalModal);
  const table_ID_internal = useSelector((state) => state.internal_table_value);
  const userDetail = useSelector((state) => state.userDetail);
  const searchKey = useSelector((state) => state.searchKey);

  const smallScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const mediumScreen = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1200px)",
  });

  function handleOnClickFilterButton() {
    setFilterModalOpened(true);
  }

  function handleOnClickSortButton() {
    setSortModalOpened(true);
  }

  function handleOnClickRow(d, key, value) {
    if (d.status === "Complete your data") {
      navigate(`/coaching/book?booking_id=${d.booking_id}`);
    } else if (key !== "feedback" && key !== "status") {
      navigate(`/session/${d["booking_id"]}`);
    }
  }

  const initialDataState = sessions
    .sort((a, b) => {
      return new Date(b.updatedAt) - new Date(a.updatedAt);
    })
    .map(
      ({
        booking_id,
        topic_id,
        scheduled_date,
        mentor,
        duration,
        status,
        user_id,
        createdAt,
      }) => {
        const assignedMentor = mentor
          ? mentor?.first_name + " " + mentor?.last_name
          : "Not available";
        return {
          booking_id,
          topic_id,
          scheduled_date,
          assignedMentor,
          duration: duration ? duration : "60",
          status,
          user_id,
          createdAt,
        };
      }
    )
    .filter((d) => d.status !== "Completed");

  // Confirm schedule for user
  function confirmSchedule(d, key, value) {
    navigate(`/coaching/confirm-schedule/${d["booking_id"]}`);
    // dispatch(SET_INTERNAL_TABLE_VALUE(d['booking_id']))
  }

  // Close modal approval
  function handleShowModalFalse() {
    dispatch(SET_APPROVAL_MODAL_FALSE());
  }

  // Handle 'Yes' button on modal
  async function handleApprovalPrimary() {
    setLoading(true);
    try {
      const response = await getDataBooking(table_ID_internal);
      if (response) {
        const result = await updateOneSession(response.data.session.id, {
          status: "Waiting for Confirmation",
        });
        if (result) {
          setLoading(false);
          Swal.fire({
            customClass: {
              popup: "mentor-popup",
            },
            imageUrl:
              "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
            position: "top-end",
            text: "Successfully updated session",
            width: "20em",
            heightAuto: "0.5em",
            showConfirmButton: false,
            timer: 1500,
          });
          window.location.reload();
        }
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  }

  // Handle 'No' button on modal
  async function handleApprovalSecondary() {
    try {
      dispatch(SET_APPROVAL_MODAL_FALSE());
    } catch (err) {
      console.error(err);
    }
  }

  function handlePageClick(e) {
    const newOffset = (e.selected * perPage) % data.length;
    setItemOffset(newOffset);
  }

  const statusList = [
    "Confirmed",
    "Waiting for Confirmation",
    "Complete your data",
    "Cancelled",
    "In review",
    "Confirm your schedule",
  ];
  const topicList = topics.map((topic) => {
    return topic.title;
  });
  const mentorList = mentors.map((mentor) => {
    return mentor.first_name + " " + mentor.last_name;
  });

  const filterKeyInitialState = {
    status: [],
    topic: [],
    dateTime: {},
    mentor: [],
  };

  const sortKeyInitialState = {
    newest: false,
    oldest: false,
  };

  const [filterKey, setFilterKey] = useState(filterKeyInitialState);
  const [sortKey, setSortKey] = useState(sortKeyInitialState);

  function handleApplyFilter() {
    if (
      Object.keys(filterKey).some(
        (key) =>
          filterKey[key].length > 0 ||
          (filterKey[key].from && filterKey[key].to)
      )
    ) {
      const filteredData = initialDataState.filter((d) => {
        let isValid = true;
        if (filterKey.status.length > 0) {
          isValid = filterKey.status.includes(d.status);
        }
        if (filterKey.topic.length > 0) {
          isValid = filterKey.topic.includes(d.topic_id.toString());
        }
        if (filterKey.mentor.length > 0) {
          isValid = filterKey.mentor.includes(d.assignedMentor);
        }
        if (filterKey.dateTime.from && filterKey.dateTime.to) {
          isValid =
            new Date(d.scheduled_date) >= new Date(filterKey.dateTime.from) &&
            new Date(d.scheduled_date) <= new Date(filterKey.dateTime.to);
        }
        return isValid;
      });
      setData(filteredData);
    } else {
      setData(initialDataState);
    }
    setFilterChanged(false);
  }
  const [isFilterChanged, setFilterChanged] = useState(false);

  function handleOnFilter(type, name, value) {
    setLoading(true);
    setFilterChanged(true);
    switch (type) {
      case "status":
        if (value) {
          setFilterKey((prev) => ({ ...prev, status: [...prev.status, name] }));
        } else {
          setFilterKey((prev) => ({
            ...prev,
            status: prev.status.filter((d) => d !== name),
          }));
        }
        break;
      case "topic":
        if (value) {
          setFilterKey((prev) => ({ ...prev, topic: [...prev.topic, name] }));
        } else {
          setFilterKey((prev) => ({
            ...prev,
            topic: prev.topic.filter((d) => d !== name),
          }));
        }
        break;
      case "date-time":
        if (value) {
          setFilterKey((prev) => ({ ...prev, dateTime: value }));
        }
        break;
      case "mentor":
        if (value) {
          setFilterKey((prev) => ({ ...prev, mentor: [...prev.mentor, name] }));
        } else {
          setFilterKey((prev) => ({
            ...prev,
            mentor: prev.mentor.filter((d) => d !== name),
          }));
        }
        break;
      case "reset":
        setFilterKey(filterKeyInitialState);
        break;
      case "apply-filter":
        handleApplyFilter();
        setFilterModalOpened(false);
        break;
      default:
        break;
    }
    setLoading(false);
  }

  function handleOnSort(type) {
    switch (type) {
      case "newest":
        const sortedData = initialDataState.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setData(sortedData);
        setSortKey((prev) => ({ ...prev, newest: true, oldest: false }));
        break;
      case "oldest":
        const sortedData2 = initialDataState.sort((a, b) => {
          return new Date(a.createdAt) - new Date(b.createdAt);
        });
        setData(sortedData2);
        setSortKey((prev) => ({ ...prev, newest: false, oldest: true }));
        break;
      default:
        break;
    }
    setSortModalOpened(false);
  }

  const handleFetchUsersSessions = async (userId, searchKey) => {
    setLoading(true);
    const result = await getAllUsersSessions(userId);
    if (result && result.data) {
      setSessions(result.data);
      let initialDataState = result.data
        .sort((a, b) => {
          return new Date(b.updatedAt) - new Date(a.updatedAt);
        })
        .map(
          ({
            booking_id,
            topic_id,
            scheduled_date,
            mentor,
            duration,
            status,
            user_id,
            createdAt,
          }) => {
            const assignedMentor = mentor
              ? mentor?.first_name + " " + mentor?.last_name
              : "Not available";
            let finalTopicId = topics.filter((topic) => {
              if (topic_id === topic.id) {
                return topic;
              }
            });
            return {
              booking_id,
              topic_id:
                finalTopicId && finalTopicId[0] && finalTopicId[0].title
                  ? finalTopicId[0].title
                  : topic_id,
              scheduled_date,
              assignedMentor,
              duration: duration ? duration : "60",
              status,
              user_id,
              createdAt,
            };
          }
        )
        .filter((d) => d.status !== "Completed");
      if (searchKey) {
        initialDataState = initialDataState.filter((data) => {
          const { booking_id, assignedMentor, topic_id } = data || {};
          let lowercaseSearchkey = searchKey.toLowerCase();
          let bookingId = booking_id.toLowerCase();
          let mentorName = assignedMentor.toLowerCase();
          let topicName = topic_id.toLowerCase();
          if (
            bookingId.includes(lowercaseSearchkey) ||
            mentorName.includes(lowercaseSearchkey) ||
            topicName.includes(lowercaseSearchkey)
          ) {
            return data;
          }
        });
      }
      setData(initialDataState);
      const endOffset = itemOffset + perPage;
      setPaginatedData(initialDataState.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(data.length / perPage));
    }
    setLoading(false);
  };

  const handleFetchTopics = async () => {
    setLoading(true);
    const result = await getAllTopics();
    if (result && result.data) {
      setTopics(result.data);
    }
    setLoading(false);
  };

  const handleFetchMentors = async () => {
    setLoading(true);
    const result = await getAllMentors();
    if (result && result.data) {
      setMentors(result.data);
    }
    setLoading(false);
  };

  const handleFilterBySearchKey = (searchKey) => {
    if (!searchKey) {
      if (userDetail && userDetail.id) {
        handleFetchUsersSessions(userDetail.id);
      }
    } else {
      if (userDetail && userDetail.id) {
        handleFetchUsersSessions(userDetail.id, searchKey);
      }
    }
  };

  const handleOnClickBtnBookSession = (userDetail) => {
    if (userDetail && userDetail.id) {
      const { is_validated, is_new_user, user_details } = userDetail || {};
      if (is_validated) {
        if (
          !is_new_user &&
          user_details &&
          user_details.first_name &&
          user_details.last_name &&
          user_details.gender &&
          user_details.phone_number
        ) {
          navigate("/coaching/book");
        } else {
          Swal.fire({
            customClass: {
              popup: "mentor-popup",
            },
            position: "top-end",
            icon: "error",
            title: "Please fill your profile first",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        Swal.fire({
          customClass: {
            popup: "mentor-popup",
          },
          position: "top-end",
          icon: "error",
          title: "Please verify your account first",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } else {
      Swal.fire({
        customClass: {
          popup: "mentor-popup",
        },
        position: "top-end",
        icon: "error",
        title: "Please login first",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  useEffect(() => {
    if (userDetail && userDetail.id) {
      handleFetchUsersSessions(userDetail.id);
      handleFetchTopics();
      handleFetchMentors();
    }
  }, [userDetail]);

  useEffect(() => {
    const endOffset = itemOffset + perPage;
    setPaginatedData(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / perPage));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, itemOffset, perPage]);

  useEffect(() => {
    setSortKey((prev) => ({ ...prev, newest: true, oldest: false }));
  }, []);

  useEffect(() => {
    handleFilterBySearchKey(searchKey);
  }, [searchKey]);

  return (
    <div
      style={{
        marginLeft: smallScreen ? "1em" : "",
        marginRight: smallScreen ? "1em" : "",
      }}
    >
      <HeaderComponent />
      <SidebarComponent />
      <SpinnerComponent
        isShown={isLoading && !smallScreen && !mediumScreen}
        right="20px"
        top="20px"
      />
      {isLoading && (smallScreen || mediumScreen) ? (
        <LoadingModalMobile />
      ) : (
        <>
          {sessions && Array.isArray(sessions) && sessions.length > 0 ? (
            <>
              <BookingContainer>
                <MyBookingInnerContainer className="myBookingInnerContainer">
                  <ButtonContainer>
                    <FilterSortButton onClick={handleOnClickSortButton}>
                      <img
                        src={sort_icon}
                        alt="sort_icon"
                        height={15}
                        width={15}
                        style={{ objectFit: "scale-down" }}
                      />
                      Sort
                    </FilterSortButton>
                    <FilterSortButton onClick={handleOnClickFilterButton}>
                      <img
                        src={filter_icon}
                        alt="filter_icon"
                        height={15}
                        width={15}
                        style={{ objectFit: "scale-down" }}
                      />
                      Filter
                    </FilterSortButton>
                  </ButtonContainer>
                  <FilterModal
                    show={isFilterModalOpened.toString()}
                    handleClose={() => {
                      setFilterModalOpened(false);
                      setFilterChanged(false);
                    }}
                    statusList={statusList}
                    topics={topics}
                    mentorList={mentorList}
                    filterKey={filterKey}
                    handleOnFilter={handleOnFilter}
                    dateTimeButtonList={["This Week", "This Month"]}
                    isFilterChanged={isFilterChanged}
                    user_detail={userDetail}
                    filterStatus={true}
                    filterTopic={true}
                    filterDateTime={true}
                    filterMentor={true}
                    smallScreen={smallScreen}
                  />
                  <SortModal
                    show={isSortModalOpened.toString()}
                    handleClose={() => {
                      setSortModalOpened(false);
                    }}
                    handleOnSort={handleOnSort}
                    sortKey={sortKey}
                    user_detail={userDetail}
                  />
                  {smallScreen ? (
                    <GreenButton
                      width={"100%"}
                      height={"40px"}
                      textSize={fontSize.title4}
                      text="Book a session"
                      action={() => handleOnClickBtnBookSession(userDetail)}
                      cursor="pointer"
                      style={{ marginTop: "1em" }}
                    />
                  ) : null}
                  <Pagination
                    handlePageClick={handlePageClick}
                    pageCount={pageCount}
                    itemOffset={itemOffset + 1}
                    endOffset={itemOffset + perPage}
                    totalItems={initialDataState.length}
                    smallScreen={smallScreen}
                  >
                    {paginatedData &&
                    Array.isArray(paginatedData) &&
                    paginatedData.length > 0 ? (
                      <div>
                        {smallScreen ? (
                          <div>
                            {paginatedData.map((data) => {
                              const {
                                id,
                                booking_id,
                                topic_id,
                                scheduled_date,
                                assignedMentor,
                                duration,
                                status,
                              } = data || {};
                              return (
                                <EachMobileDataMainContainer
                                  key={id}
                                  onClick={() =>
                                    handleOnClickRow(data, "detail-session")
                                  }
                                >
                                  <EachMobileDataContainer>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        marginBottom: "0.5em",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "0.5em",
                                          width: "50%",
                                        }}
                                      >
                                        <div
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "10px",
                                          }}
                                        >
                                          Booking ID:
                                        </div>
                                        <div style={{ fontSize: "10px" }}>
                                          {booking_id}
                                        </div>
                                      </div>
                                      <div
                                        style={{ display: "flex", gap: "1em" }}
                                      >
                                        <StatusComponent
                                          status={status}
                                          border_radius="40px"
                                          fontSize="10px"
                                          onClickStatusRow={() =>
                                            confirmSchedule(
                                              data,
                                              "status",
                                              status
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div
                                      style={{ display: "flex", gap: "0.5em" }}
                                    >
                                      <div
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "10px",
                                        }}
                                      >
                                        Description:
                                      </div>
                                      <div style={{ fontSize: "10px" }}>
                                        {topic_id}
                                      </div>
                                    </div>
                                    <div
                                      style={{ display: "flex", gap: "0.5em" }}
                                    >
                                      <div
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "10px",
                                        }}
                                      >
                                        Session date/time:
                                      </div>
                                      <div style={{ fontSize: "10px" }}>
                                        {scheduled_date
                                          ? dateFormat(scheduled_date)
                                          : "Not available"}
                                      </div>
                                    </div>
                                    <div
                                      style={{ display: "flex", gap: "0.5em" }}
                                    >
                                      <div
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "10px",
                                        }}
                                      >
                                        Assigned mentor:
                                      </div>
                                      <div style={{ fontSize: "10px" }}>
                                        {assignedMentor || "Not available"}
                                      </div>
                                    </div>
                                    <div
                                      style={{ display: "flex", gap: "0.5em" }}
                                    >
                                      <div
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "10px",
                                        }}
                                      >
                                        Duration:
                                      </div>
                                      <div style={{ fontSize: "10px" }}>
                                        {duration}
                                      </div>
                                    </div>
                                  </EachMobileDataContainer>
                                </EachMobileDataMainContainer>
                              );
                            })}
                          </div>
                        ) : (
                          <TableComponent
                            table_data={paginatedData}
                            topics={topics}
                            onClickRow={handleOnClickRow}
                            setTableID={confirmSchedule}
                            user_detail={userDetail}
                          />
                        )}
                      </div>
                    ) : null}
                  </Pagination>
                </MyBookingInnerContainer>
              </BookingContainer>
              <ModalComponent
                show={approvalModal}
                handleClose={handleShowModalFalse}
                body={[
                  <p style={{ fontWeight: 700 }} key={0}>
                    The session will be held on <strong>{scheduledDate}</strong>
                    , are you sure want to approve?
                  </p>,
                ]}
                buttonPrimary="Yes"
                buttonSecondary="No"
                onPrimaryClick={handleApprovalPrimary}
                onSecondaryClick={handleApprovalSecondary}
              />
            </>
          ) : (
            <div style={{ paddingLeft: "350px" }}>
              <div className="myBookingInnerContainerEmptySession">
                <img src={empty_session_1_icon} alt="empty_session_1_icon" />
                <h4 style={{ fontSize: "22px" }}>
                  You have not made any booking yet
                </h4>
                <GreenButton
                  width="180px"
                  height="35px"
                  textSize={font_size.title3}
                  text="Book your first session"
                  action={() => navigate("/coaching/book")}
                  cursor="pointer"
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default MyBooking;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const FilterSortButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: black solid 1px;
  background-color: #f0f8ff;
  color: black;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 16px;
  user-select: none;
  border-radius: 10px;
  :hover {
    filter: brightness(95%);
  }
  z-index: ${({ show }) => (show ? "2000" : "1")};
`;

const BookingContainer = styled.div`
  @media ${device.laptopM} {
    padding-left: 350px;
  }
  @media ${device.laptopL} {
    padding-left: 350px;
  }
`;

const MyBookingInnerContainer = styled.div`
  padding: 80px 3vw 90px 3vw;
  text-align: left;
  @media ${device.tablet} {
    padding: 130px 3vw 90px 3vw;
  }
  @media ${device.laptop} {
    padding: 130px 3vw 90px 3vw;
  }
  @media ${device.laptopM} {
    padding: 130px 3vw 30px 3vw;
  }
  @media ${device.laptopL} {
    padding: 130px 3vw 30px 3vw;
  }
`;

const EachMobileDataMainContainer = styled.div`
  border-radius: 10px;
  width: 100%;
  border: 1px solid #2c65f7;
  margin-top: 1em;
  margin-bottom: 1em;
  cursor: pointer;
`;

const EachMobileDataContainer = styled.div`
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 0.5em;
  margin-right: 0.5em;
`;

import React, { useState, useEffect } from "react";

// date-fns
import { format, isValid, parse } from "date-fns";

import styled from "styled-components";
import fontSize from "../../../lib/styling/font_size";

import Form from "react-bootstrap/Form";

import DateTimeInput from "../../input/DateTimeInput";

// sweetalert
import Swal from "sweetalert2";

function NonEntrepreneurshipForm({
  nonEntreForm,
  handleChange,
  form,
  setForm,
  handleBlur,
  setFormEdited,
  setIsError,
  scheduleData,
  smallScreen,
}) {
  const [focusedInput, setFocusedInput] = useState(null);
  const [inputValueDate, setInputValueDate] = useState("");

  function handleSelectDate(value, valueDate) {
    if (!value) {
      // for custom input date
      setInputValueDate(valueDate);
      const date = parse(valueDate, "dd-MM-y", new Date());
      if (isValid(date) && valueDate.length === 10) {
        setForm({
          ...form,
          scheduled_date: {
            ...form.date,
            value: date,
          },
          time: {
            ...form.time,
            value: "",
          },
        });
      } else {
        if (valueDate.length === 10 || valueDate.length > 10) {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `Please input with corrent format date. Ex : ${format(
              new Date(),
              "dd-MM-y"
            )}`,
            showConfirmButton: false,
            timer: 3000,
          });
        }
      }
    } else {
      setInputValueDate(format(value, "dd-MM-y", new Date()));
      const editedValue = new Date(value.setHours(""));
      setForm({
        ...form,
        scheduled_date: {
          ...form.date,
          value: editedValue,
        },
        time: {
          ...form.time,
          value: "",
        },
      });
      setFormEdited(true);
      setIsError(
        Object.values(form).some((el) => el.required && el.value === "")
      );
    }
  }

  function handleSelectTime(value, id) {
    setForm({
      ...form,
      time: {
        ...form.time,
        value,
      },
      timeslot_id: id,
    });
    setFormEdited(true);

    setIsError(
      Object.values(form).some((el) => el.required && el.value === "")
    );
  }
  
  return (
    <>
      {nonEntreForm?.map((input, index) => {
        if (input.type === "datetime") {
          return (
            <InputGroup key={index}>
              <Form.Group>
                <InputLabel>{input.label}</InputLabel>
                <DateTimeInput
                  data={scheduleData}
                  placeholder={input.placeholder}
                  icon={input.icon}
                  value={input.value}
                  selectedDate={form.scheduled_date.value}
                  selectedTime={form.time.value}
                  handleSelectDate={handleSelectDate}
                  handleSelectTime={handleSelectTime}
                  inputValueDate={inputValueDate}
                  smallScreen={smallScreen}
                />
              </Form.Group>
            </InputGroup>
          );
        } else if (input.type === "textarea") {
          return (
            <InputGroup key={index}>
              <Form.Group>
                <InputLabel>{input.label}</InputLabel>
                <TextArea
                  rows={input.rows}
                  placeholder={input.placeholder}
                  value={form?.[`${input.value}`]?.value || ""}
                  onChange={(e) => handleChange(e, `${input.value}`)}
                  onBlur={handleBlur}
                  onFocus={() => setFocusedInput(input.value)}
                  style={{
                    borderColor: form?.[input.value]?.error
                      ? "#FF9494"
                      : undefined,
                    fontWeight:
                      focusedInput !== input.value &&
                      form?.[`${input.value}`]?.value
                        ? "bold"
                        : undefined,
                  }}
                  maxLength={255}
                />
              </Form.Group>
            </InputGroup>
          );
        }
      })}
    </>
  );
}

export default NonEntrepreneurshipForm;

const InputGroup = styled.div`
  margin-bottom: 20px;
`;
const InputLabel = styled.div`
  margin-bottom: 5px;
  font-size: ${fontSize.title2};
  font-weight: bold;
`;

const TextArea = styled.textarea`
  width: 100%;
  border: 2px solid #00bb99;
  padding: 10px 15px;
  border-radius: 10px;
  &:focus {
    outline: none;
    border-width: 3px;
  }
`;

import Axios from "../../helpers/axios";

export const getDetailInternalMentor = (mentor_id) => {
  return Axios.get(`/mentors/${mentor_id}`);
};

export const getAllMentorTopics = (mentor_id) => {
  return Axios.get(`/mentors/topic-mentor/${mentor_id}`);
};

export const getAllMentorSchedules = (mentor_id, params) => {
  return Axios.get(
    `/mentors/get-schedules/${mentor_id}`,
    params ? { params } : null
  );
};

export const getMentorSchedulesAllTime = (mentor_id) => {
  return Axios.get(`/mentors/get-schedules-all/${mentor_id}`);
};

export const updateMentor = (mentor_id, body) => {
  return Axios.patch(`/mentors/${mentor_id}`, body);
};

export const createMultipleTopics = (mentor_id, mentorTopicsId) => {
  return Axios.post(`/mentors/add-topics/${mentor_id}`, null, {
    params: {
      topicId: mentorTopicsId,
    },
  });
};

export const createMentorSchedule = (mentor_id, body, params) => {
  return Axios.post(`/mentors/add-schedule/${mentor_id}`, body, {
    params,
  });
};

export const editMentorSchedule = (mentor_id, body, params) => {
  return Axios.patch(`/mentors/edit-schedule/${mentor_id}`, body, {
    params,
  });
};

export const getScheduleTimeslot = (mentor_id, params) => {
  return Axios.get(
    `/mentors/get-schedule-timeslots/${mentor_id}`,
    params ? { params } : null
  );
};

export const createNewMentor = (body) => {
  return Axios.post(`/mentors/create`, body);
};

export const changeMentorStatus = (mentor_id, body) => {
  return Axios.patch(`/mentors/active-status/${mentor_id}`, body);
};

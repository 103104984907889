import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// moment js
import moment from "moment";

// Images and Icons
import arrow_left_icon_dark from "../assets/icons/arrow_left_icon_dark.png";

const MobileNotificationPage = () => {
  const navigate = useNavigate();
  const usersNotifications = useSelector((state) => state.usersNotifications);

  const handleOnClickNotification = (url) => {
    navigate(url);
  };

  const handleFormatNotificationDateTimeFormat = (date) => {
    let finalDateValue = moment(new Date(date)).startOf("second").fromNow();
    if (finalDateValue.includes("a few") === false) {
      finalDateValue = finalDateValue.replace("a ", "1  ");
    }
    finalDateValue = finalDateValue.replace("an", "1");
    finalDateValue = finalDateValue.replace("minute", "min");
    finalDateValue = finalDateValue.replace("minutes", "mins");
    finalDateValue = finalDateValue.replace("seconds", "secs");
    finalDateValue = finalDateValue.replace("ago", "");
    return finalDateValue;
  };

  return (
    <div className="notificationMainContainer">
      <div className="notificationHeaderContainer">
        <div onClick={() => navigate(-1)}>
          <img
            src={arrow_left_icon_dark}
            alt="arrow_left_icon_dark"
            style={{
              objectFit: "scale-down",
              objectPosition: "left bottom",
              height: "15px",
            }}
          />
        </div>
        <div className="notificationTitleStyle">Notifications</div>
      </div>
      <div className="notificationHrStyle"></div>
      <div className="notificationBodyContainer">
        {usersNotifications &&
        Array.isArray(usersNotifications) &&
        usersNotifications.length > 0
          ? usersNotifications.map((usersNotification) => {
              const {
                id,
                notification: { text, button_url, button_label, disabled } = {},
                is_read,
                createdAt,
              } = usersNotification || {};
              return (
                <div
                  key={id}
                  className="eachNotificationContainer"
                  style={{ backgroundColor: is_read ? "#ffffff" : "#eff8ff" }}
                  onClick={() =>
                    !disabled ? handleOnClickNotification(button_url) : null
                  }
                >
                  <div style={{ marginLeft: "1em", marginRight: "1em" }}>
                    <div className="notificationBodyTextContainer">
                      <div className="notificationTextStyle">{text}</div>
                      <div className="notificationTimeStyle">
                        {handleFormatNotificationDateTimeFormat(createdAt)}
                      </div>
                    </div>
                    {button_url && button_label ? (
                      <button
                        className="notificationButtonContainer"
                        style={{
                          cursor: disabled ? "not-allowed" : "pointer",
                        }}
                      >
                        <div className="notificationButtonLabel">
                          {button_label}
                        </div>
                      </button>
                    ) : null}
                  </div>
                  <div className="notificationHrStyle"></div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default MobileNotificationPage;

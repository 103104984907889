import React from "react";

import "../styles/LoadingModalMobile.css";

// Bootstrap
import Spinner from "react-bootstrap/Spinner";

const LoadingModalMobile = () => {
  return (
    <div className="loadingModalMobileMainContainer">
      <Spinner animation="border" role="status" />
    </div>
  );
};

export default LoadingModalMobile;

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// reducer
import {
  SET_IS_INTERNAL,
  SET_USER_DETAIL,
  SET_USER_ROLE,
} from "../../store/actions";

// Cookies
import Cookies from "js-cookie";

// Apis
import { checkUserSession } from "../../apis/authentications";

const LoginGuard = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = Cookies.get("ut");

  const handleGetUsersProfile = async () => {
    try {
      const result = await checkUserSession();
      if (result && result.data) {
        const { data: { is_admin, is_new_user, role } = {} } = result || {};
        dispatch(SET_USER_DETAIL(result.data));
        // Set user role
        dispatch(SET_USER_ROLE(role?.name));
        if (is_admin) {
          dispatch(SET_IS_INTERNAL(true));
          if (role && (+role === 1 || +role === 2)) {
            navigate("/internal/users");
          }
          if (role && +role === 4) {
            navigate("/internal/bookings");
          }
        } else {
          dispatch(SET_IS_INTERNAL(false));
          if (is_new_user) {
            navigate("/profile");
          } else {
            navigate("/my-booking");
          }
        }
      } else {
        navigate("/login");
      }
    } catch (err) {
      navigate("/login");
    }
  };

  useEffect(() => {
    if (token) {
      handleGetUsersProfile();
    } else {
      navigate("/login");
    }
  }, [token]);

  if (!token) {
    navigate("/login");
  } else {
    return children;
  }
};

export default LoginGuard;

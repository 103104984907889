import React from "react";

export default function YesNoInput({
  isError,
  onClick,
  placeholder,
  name,
  onChange,
  value,
  onBlur,
  onFocus,
  focusedInput,
  inputBackgroundColor,
  disabled,
  user_detail,
  propStyle,
}) {
  return (
    <div
      style={{
        width: "100%",
        border: isError
          ? "2px solid #FF9494"
          : user_detail && user_detail.is_admin === false
          ? "2px solid #2c65f7"
          : "2px solid #00BB99",
        padding: "10px 15px",
        borderRadius: "10px",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: isError ? 0 : 20,
        backgroundColor: inputBackgroundColor || undefined,
        opacity: disabled ? 0.5 : 1,
        fontWeight: focusedInput !== name && value ? "bold" : undefined,
        ...propStyle,
      }}
      onClick={onClick}
    >
      <div>{placeholder}</div>
      <div>
        <input
          style={{ marginRight: "5px" }}
          type="radio"
          id={`no-${placeholder}`}
          name={name}
          value={`no`}
          onChange={onChange}
          onFocus={onFocus}
          checked={value === "no" ? true : false}
        />
        <label style={{ marginRight: "10px" }} htmlFor={`no-${placeholder}`}>
          No
        </label>
        <input
          style={{ marginRight: "5px" }}
          type="radio"
          id={`yes-${placeholder}`}
          name={name}
          value={`yes`}
          onChange={onChange}
          onFocus={onFocus}
          checked={value === "yes" ? true : false}
        />
        <label style={{ marginRight: "10px" }} htmlFor={`yes-${placeholder}`}>
          Yes
        </label>
      </div>
    </div>
  );
}

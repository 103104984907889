import React from "react";

function GreenButton({
  width,
  height,
  textSize,
  text = "next",
  action,
  bgcolor = "#00BB99",
  opacity = 1,
  cursor = "pointer",
  isError = false,
  disabled = false,
  style,
}) {
  return (
    <div
      className="greenBtnStyle"
      style={{
        opacity,
        background: bgcolor,
        cursor,
        height,
        width,
        ...style,
      }}
      onClick={!disabled ? action : null}
    >
      <div
        style={{
          fontSize: textSize,
          color: "white",
          fontWeight: "bold",
          userSelect: "none",
        }}
      >
        {text}
      </div>
    </div>
  );
}

export default GreenButton;

import React from "react";

// Style
import "../styles/headerComponent.css";

const ModalProfile = (props) => {
  const { onClickMyProfile, onClickLogOut } = props || {};

  return (
    <div className="profileMainContainer">
      <div
        className="notificationHeaderContainer"
        onClick={() => onClickMyProfile()}
      >
        <div className="notificationTitleStyle">My profile</div>
      </div>
      <div className="notificationHrStyle"></div>
      <div
        className="notificationHeaderContainer"
        onClick={() => onClickLogOut()}
      >
        <div className="notificationTitleStyle">Log out</div>
      </div>
      <div className="notificationHrStyle"></div>
    </div>
  );
};

export default ModalProfile;

import React from "react";

// React bootstrap
import Alert from "react-bootstrap/Alert";

// Icons & Images
import success_icon from "../assets/icons/success_icon.svg";
import danger_icon from "../assets/icons/danger_icon.svg";

// Styles
import "../styles/AlertComponent.css";

const AlertComponent = ({ variant, text, isShown }) => {
  const innerWidth = window.innerWidth;
  return (
    <div className="alertComponentMainContainer">
      <Alert
        variant={variant}
        style={{
          display: isShown ? "flex" : "none",
          gap: "1rem",
          padding: "8px 12px",
          fontSize: innerWidth && innerWidth < 768 ? "14px" : "16px",
          textAlign: innerWidth && innerWidth < 768 ? "left" : "",
        }}
      >
        <img
          src={variant === "success" ? success_icon : danger_icon}
          className="alertComponentImage"
          alt="check_icon"
          height={20}
          width={20}
        />
        {text}
      </Alert>
    </div>
  );
};

export default AlertComponent;

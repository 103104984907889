import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

// reducer
import {
  SET_APPROVAL_MODAL_FALSE,
  SET_DECLINE_MODAL_FALSE,
  SET_INTERNAL_TABLE_VALUE,
  SET_SCHEDULE_MODAL_FALSE,
} from "../store/actions";

// lib
import font_size from "../lib/styling/font_size";

// images & icons
import assigned_mentor_icon from "../assets/icons/assigned_mentor_icon.png";
import add_mentor_icon from "../assets/icons/add_mentor_icon.png";
import empty_session_1_icon from "../assets/icons/empty_session_1_icon.svg";
import sort_icon from "../assets/icons/sort_icon.svg";
import filter_icon from "../assets/icons/filter_icon.svg";

// sweetalert
import Swal from "sweetalert2";

// components
import HeaderComponent from "../components/HeaderComponent";
import Pagination from "../components/layout/Pagination";
import TableInternalComponent from "../components/TableInternalComponent";
import ModalComponent from "../components/ModalComponent";
import CustomDropdown from "../components/input/CustomDropdown";
import SidebarInteralComponent from "../components/SidebarInternalComponent";
import GreenButton from "../components/button/GreenButton";
import SpinnerComponent from "../components/SpinnerComponent";
import FilterModal from "../components/FilterModal";
import SortModal from "../components/SortModal";

// Apis
import {
  getAllSessions,
  getDetailInternalBooking,
  updateSession,
} from "../apis/internalApis/sessions";
import { getDetailInternalMentor } from "../apis/internalApis/mentors";
import { getAllMentors } from "../apis/clientApis/mentors";
import { getAllTopics } from "../apis/topics";

const InternalBookings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const filterKeyInitialState = {
    status: [],
    topic: [],
    dateTime: {},
    mentor: [],
  };

  const scheduleModal = useSelector((state) => state.scheduleModal);
  const approvalModal = useSelector((state) => state.approvalModal);
  const declineModal = useSelector((state) => state.declineModal);
  const table_ID_internal = useSelector((state) => state.internal_table_value);
  const userDetail = useSelector((state) => state.userDetail);
  const userRole = useSelector((state) => state.userRole);
  const searchKey = useSelector((state) => state.searchKey);

  const [isLoading, setLoading] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [topics, setTopics] = useState([]);
  const [mentors, setMentors] = useState([]);

  const [isFilterModalOpened, setFilterModalOpened] = useState(false);
  const [isSortModalOpened, setSortModalOpened] = useState(false);
  const [isFilterChanged, setFilterChanged] = useState(false);
  const [filterKey, setFilterKey] = useState(filterKeyInitialState);
  const [sortKey, setSortKey] = useState({ newest: false, oldest: false });

  const [data, setData] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [paginatedData, setPaginatedData] = useState([]);
  const [perPage, setPerPage] = useState(15);
  const [pageCount, setPageCount] = useState(0);

  const [assignedMentor, setAssignedMentor] = useState("");
  const [handleMentor, setHandleMentor] = useState(false);
  const [isMentorAssigned, setMentorAssigned] = useState(false);
  const [onlineMeeting, setOnlineMeeting] = useState("");
  const [isOnlineMeeting, setIsOnlineMeeting] = useState(false);

  const [selectedTopicId, setSelectedTopicId] = useState(null);
  const [combined_mentors_name, setCombinedMentorsName] = useState([]);

  // Change route to details
  function handleOnClickRow(d, key, value) {
    if (key !== "feedback") {
      if (d.status === "Complete your data") {
        // navigate(`/coaching/book?booking_id=${d.booking_id}`);
      } else {
        navigate(`/internal/bookings/${d["booking_id"]}`);
      }
    }
  }

  // Set Table ID for modal
  function setTableID(d, key, value) {
    dispatch(SET_INTERNAL_TABLE_VALUE(d["booking_id"]));
  }

  // Close modal
  function handleShowModalFalse() {
    dispatch(SET_SCHEDULE_MODAL_FALSE());
    dispatch(SET_APPROVAL_MODAL_FALSE());
    dispatch(SET_DECLINE_MODAL_FALSE());
  }

  // Handle approve
  async function handleApprovalPrimary() {
    setLoading(true);
    try {
      const response = await getDetailInternalBooking(table_ID_internal);
      if (
        response &&
        response.data &&
        response.data.session &&
        response.data.session.id
      ) {
        const sessionId = response.data.session.id;
        const data = { status: "Confirm your schedule" };
        const result = await updateSession(sessionId, data);
        if (result) {
          setLoading(false);
          Swal.fire({
            customClass: {
              popup: "mentor-popup",
            },
            imageUrl:
              "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
            position: "top-end",
            text: "Successfully update session",
            width: "20em",
            heightAuto: "0.5em",
            showConfirmButton: false,
            timer: 1500,
          });
          window.location.reload();
        }
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }

  // Handle decline
  async function handleDeclinePrimary() {
    setLoading(true);
    try {
      const response = await getDetailInternalBooking(table_ID_internal);
      if (
        response &&
        response.data &&
        response.data.session &&
        response.data.session.id
      ) {
        const sessionId = response.data.session.id;
        const data = { status: "Declined" };
        const result = await updateSession(sessionId, data);
        if (result) {
          setLoading(false);
          Swal.fire({
            customClass: {
              popup: "mentor-popup",
            },
            imageUrl:
              "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
            position: "top-end",
            text: "Successfully update session",
            width: "20em",
            heightAuto: "0.5em",
            showConfirmButton: false,
            timer: 1500,
          });
          window.location.reload();
        }
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }

  // Cancel modal
  async function handleApprovalSecondary() {
    try {
      dispatch(SET_SCHEDULE_MODAL_FALSE());
      dispatch(SET_APPROVAL_MODAL_FALSE());
      dispatch(SET_DECLINE_MODAL_FALSE());
    } catch (err) {
      console.log(err);
    }
  }

  // Handle change dropdown on Schedule Approval
  async function handleChangeDropdown(value, key) {
    setLoading(true);
    // Assign mentor into state
    setAssignedMentor(value);
    try {
      if (key) {
        // Check if the name is the same as value
        const mentorID = combined_mentors_name.filter((mentor) => {
          if (mentor.assignedMentor === value) return mentor.id;
        });

        // Get selected mentor in database
        const mentorAssigned = await getDetailInternalMentor(mentorID[0].id);

        // Get selected sessions
        const response = await getDetailInternalBooking(table_ID_internal);

        // Check if both request is valid
        if (
          response &&
          response.data &&
          response.data.session &&
          response.data.session.id &&
          mentorAssigned
        ) {
          const sessionId = response.data.session.id;
          const data = { mentor_id: mentorID[0].id };
          // Update session with mentor ID
          const result = await updateSession(sessionId, data);
          if (result) {
            setLoading(false);
            Swal.fire({
              customClass: {
                popup: "mentor-popup",
              },
              imageUrl:
                "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
              position: "top-end",
              text: "Successfully update session",
              width: "20em",
              heightAuto: "0.5em",
              showConfirmButton: false,
              timer: 1500,
            });
            return setHandleMentor(true);
          }
        } else {
          setLoading(false);
          console.log("Request not valid");
        }
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }

  // Handle onChange online meeting
  async function handleOnlineMeeting(event) {
    try {
      // Check link with pattern
      const pattern =
        /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
      const isUrl = pattern.test(event.target.value);

      // Check if online meeting has been filled
      if (
        event.target.value !== "" ||
        event.target.value !== undefined ||
        event.target.value !== null
      ) {
        if (isUrl) {
          setIsOnlineMeeting(true);
          setOnlineMeeting(event.target.value);
        } else {
          setIsOnlineMeeting(false);
          console.log("Please insert your online meeting link - 2");
        }
      } else {
        setIsOnlineMeeting(false);
        console.log("Please insert your online meeting link - 1");
      }
    } catch (err) {
      console.log(err);
    }
  }

  // Handle next button 'Schedule Approval'
  function handleNextModal() {
    if (handleMentor) {
      setMentorAssigned(true);
    } else {
      console.log("Fail to click next");
    }
  }

  // Handle back buton 'Schedule Approval'
  function handleBackButton() {
    setMentorAssigned(false);
  }

  // Handle submit button 'Schedule Approval'
  async function handleSubmitButton() {
    setLoading(true);
    try {
      if (isOnlineMeeting) {
        // Get selected sessions
        const response = await getDetailInternalBooking(table_ID_internal);

        if (
          response &&
          response.data &&
          response.data.session &&
          response.data.session.id
        ) {
          const sessionId = response.data.session.id;
          const data = {
            external_link: onlineMeeting,
            status: "Confirmed",
          };
          const result = await updateSession(sessionId, data);
          if (result) {
            setLoading(false);
            Swal.fire({
              customClass: {
                popup: "mentor-popup",
              },
              imageUrl:
                "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
              position: "top-end",
              text: "Successfully update session",
              width: "20em",
              heightAuto: "0.5em",
              showConfirmButton: false,
              timer: 1500,
            });
            window.location.reload();
          } else {
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: "Failed to submit",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } else {
          setLoading(false);
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Failed to find session",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        setLoading(false);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Please insert your link",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }

  // Pagination - 1
  function handlePageClick(e) {
    const newOffset = (e.selected * perPage) % data.length;
    setItemOffset(newOffset);
  }

  const handleFilterMentorsSessionForUserLoginMentor = (
    arrSessions,
    userDetail
  ) => {
    let finalArrSession = arrSessions;
    let arrTopicMentor =
      (userDetail && userDetail.mentor && userDetail.mentor.topics) || null;
    let mentorTopicIds =
      arrTopicMentor &&
      Array.isArray(arrTopicMentor) &&
      arrTopicMentor.length > 0 &&
      arrTopicMentor.map((topic) => topic.id);
    if (userRole === "Mentor") {
      finalArrSession = finalArrSession.filter((session) => {
        const { mentor, topic_id } = session || {};
        if (
          (mentor &&
            mentor.id &&
            userDetail &&
            userDetail.mentor &&
            userDetail.mentor.id &&
            mentor.id === userDetail.mentor.id) ||
          (mentorTopicIds &&
            mentorTopicIds.length > 0 &&
            mentorTopicIds.includes(topic_id))
        ) {
          return session;
        }
      });
    }
    return finalArrSession;
  };

  const handleFetchUsersSessions = async () => {
    setLoading(true);
    const result = await getAllSessions();
    if (result && result.data) {
      let arrSessions = result.data;
      if (userRole && userRole === "Mentor") {
        arrSessions = handleFilterMentorsSessionForUserLoginMentor(
          arrSessions,
          userDetail
        );
      }
      setSessions(arrSessions);
    }
    setLoading(false);
  };

  const handleFetchTopics = async () => {
    setLoading(true);
    const result = await getAllTopics();
    if (result && result.data) {
      setTopics(result.data);
    }
    setLoading(false);
  };

  const handleFetchMentors = async () => {
    setLoading(true);
    const result = await getAllMentors();
    if (result && result.data) {
      setMentors(result.data);
    }
    setLoading(false);
  };

  const handleFormatFirstFetchPaginatedData = (sessions, searchKey) => {
    let initialDataState = sessions
      .sort((a, b) => {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      })
      .map(
        ({
          booking_id,
          topic_id,
          scheduled_date,
          mentor,
          status,
          user_id,
          createdAt,
        }) => {
          const assignedMentor = mentor
            ? mentor?.first_name + " " + mentor?.last_name
            : "Not available";
          let finalTopicId = topics.filter((topic) => {
            if (topic_id === topic.id) {
              return topic;
            }
          });
          return {
            booking_id,
            topic_id:
              finalTopicId && finalTopicId[0] && finalTopicId[0].title
                ? finalTopicId[0].title
                : topic_id,
            scheduled_date,
            assignedMentor,
            duration: "60",
            booking_created: createdAt,
            status,
            user_id,
          };
        }
      );
    if (searchKey) {
      initialDataState = initialDataState.filter((data) => {
        const { booking_id, assignedMentor, topic_id } = data || {};
        let lowercaseSearchkey = searchKey.toLowerCase();
        let bookingId = booking_id.toLowerCase();
        let mentorName = assignedMentor.toLowerCase();
        let topicName = topic_id.toLowerCase();
        if (
          bookingId.includes(lowercaseSearchkey) ||
          mentorName.includes(lowercaseSearchkey) ||
          topicName.includes(lowercaseSearchkey)
        ) {
          return data;
        }
      });
    }
    setData(initialDataState);
    const endOffset = itemOffset + perPage;
    setPaginatedData(initialDataState.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / perPage));
  };

  function handleOnClickFilterButton() {
    setFilterModalOpened(true);
  }

  function handleOnClickSortButton() {
    setSortModalOpened(true);
  }

  function handleOnFilter(type, name, value) {
    setFilterChanged(true);
    switch (type) {
      case "status":
        if (value) {
          setFilterKey((prev) => ({ ...prev, status: [...prev.status, name] }));
        } else {
          setFilterKey((prev) => ({
            ...prev,
            status: prev.status.filter((d) => d !== name),
          }));
        }
        break;
      case "topic":
        if (value) {
          setFilterKey((prev) => ({ ...prev, topic: [...prev.topic, name] }));
        } else {
          setFilterKey((prev) => ({
            ...prev,
            topic: prev.topic.filter((d) => d !== name),
          }));
        }
        break;
      case "date-time":
        if (value) {
          setFilterKey((prev) => ({ ...prev, dateTime: value }));
        }
        break;
      case "mentor":
        if (value) {
          setFilterKey((prev) => ({ ...prev, mentor: [...prev.mentor, name] }));
        } else {
          setFilterKey((prev) => ({
            ...prev,
            mentor: prev.mentor.filter((d) => d !== name),
          }));
        }
        break;
      case "reset":
        setFilterKey(filterKeyInitialState);
        break;
      case "apply-filter":
        handleApplyFilter();
        setFilterModalOpened(false);
        break;
      default:
        break;
    }
  }

  function handleOnSort(type) {
    switch (type) {
      case "newest":
        const sortedData = initialDataState.sort((a, b) => {
          return new Date(b.booking_created) - new Date(a.booking_created);
        });
        setData(sortedData);
        setSortKey((prev) => ({ ...prev, newest: true, oldest: false }));
        break;
      case "oldest":
        const sortedData2 = initialDataState.sort((a, b) => {
          return new Date(a.booking_created) - new Date(b.booking_created);
        });
        setData(sortedData2);
        setSortKey((prev) => ({ ...prev, newest: false, oldest: true }));
        break;
      default:
        break;
    }
    setSortModalOpened(false);
  }

  function handleApplyFilter() {
    if (
      Object.keys(filterKey).some(
        (key) =>
          filterKey[key].length > 0 ||
          (filterKey[key].from && filterKey[key].to)
      )
    ) {
      const filteredData = initialDataState.filter((d) => {
        let isValid = true;
        if (filterKey.status.length > 0) {
          isValid = filterKey.status.includes(d.status);
        }
        if (filterKey.topic.length > 0) {
          isValid = filterKey.topic.includes(d.topic_id.toString());
        }
        if (filterKey.mentor.length > 0) {
          isValid = filterKey.mentor.includes(d.assignedMentor);
        }
        if (filterKey.dateTime.from && filterKey.dateTime.to) {
          isValid =
            new Date(d.scheduled_date) >= new Date(filterKey.dateTime.from) &&
            new Date(d.scheduled_date) <= new Date(filterKey.dateTime.to);
        }
        return isValid;
      });
      setData(filteredData);
    } else {
      setData(initialDataState);
    }
    setFilterChanged(false);
  }
  const handleFilterBySearchKey = (searchKey) => {
    if (!searchKey) {
      handleFormatFirstFetchPaginatedData(sessions);
    } else {
      handleFormatFirstFetchPaginatedData(sessions, searchKey);
    }
  };

  useEffect(() => {
    if (userDetail && userDetail.id) {
      handleFetchUsersSessions(userDetail.id);
      handleFetchTopics();
      handleFetchMentors();
    }
  }, [userDetail]);

  useEffect(() => {
    if (sessions && Array.isArray(sessions) && sessions.length > 0) {
      handleFormatFirstFetchPaginatedData(sessions);
    }
  }, [sessions]);

  // Pagination - 2
  useEffect(() => {
    const endOffset = itemOffset + perPage;
    setPaginatedData(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / perPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, itemOffset, perPage]);

  useEffect(() => {
    handleFilterBySearchKey(searchKey);
  }, [searchKey]);

  const getBookingTopic = async (table_ID_internal) => {
    const response = await getDetailInternalBooking(table_ID_internal);
    if (
      response &&
      response.data &&
      response.data.session &&
      response.data.session.id
    ) {
      const { topic_id } = response.data.session;
      if (topic_id) {
        setSelectedTopicId(topic_id);
        const combined_mentors_name = mentors
          .filter((mentor) => {
            const { topics, mentor_status } = mentor || {};
            let arrMentorTopicIds =
              topics && Array.isArray(topics) && topics.length > 0
                ? topics.map((topic) => topic.id)
                : [];
            if (arrMentorTopicIds.includes(topic_id) && mentor_status) {
              return mentor;
            }
          })
          .map((mentor) => {
            const { id, first_name, last_name } = mentor || {};
            const assignedMentor =
              first_name && last_name ? first_name + " " + last_name : null;

            return {
              id,
              assignedMentor,
              first_name,
              last_name,
            };
          });
        setCombinedMentorsName(combined_mentors_name);
      }
    }
  };

  useEffect(() => {
    if (table_ID_internal) {
      getBookingTopic(table_ID_internal);
    }
  }, [table_ID_internal]);

  const initialDataState = sessions
    .sort((a, b) => {
      return new Date(b.updatedAt) - new Date(a.updatedAt);
    })
    .map(
      ({
        booking_id,
        topic_id,
        scheduled_date,
        mentor,
        duration,
        status,
        user_id,
        createdAt,
      }) => {
        const assignedMentor = mentor
          ? mentor?.first_name + " " + mentor?.last_name
          : "Not available";
        return {
          booking_id,
          topic_id,
          scheduled_date,
          assignedMentor,
          duration: "60",
          booking_created: createdAt,
          status,
          user_id,
        };
      }
    );

  const statusList = [
    "Confirmed",
    "Waiting for Confirmation",
    "Complete your data",
    "Cancelled",
    "In review",
    "Confirm your schedule",
  ];

  const mentorList = mentors.map((mentor) => {
    return mentor.first_name + " " + mentor.last_name;
  });

  return (
    <div>
      <HeaderComponent />
      <SidebarInteralComponent />
      <SpinnerComponent isShown={isLoading} right="20px" top="20px" />
      {sessions && Array.isArray(sessions) && sessions.length > 0 ? (
        <>
          <OuterContainer>
            <InnerContainer>
              <ButtonContainer>
                <FilterSortButton onClick={handleOnClickSortButton}>
                  <img
                    src={sort_icon}
                    alt="sort_icon"
                    height={15}
                    width={15}
                    style={{ objectFit: "scale-down" }}
                  />
                  Sort
                </FilterSortButton>
                <FilterSortButton onClick={handleOnClickFilterButton}>
                  <img
                    src={filter_icon}
                    alt="filter_icon"
                    height={15}
                    width={15}
                    style={{ objectFit: "scale-down" }}
                  />
                  Filter
                </FilterSortButton>
              </ButtonContainer>
              <FilterModal
                show={isFilterModalOpened.toString()}
                handleClose={() => {
                  setFilterModalOpened(false);
                  setFilterChanged(false);
                }}
                statusList={statusList}
                topics={topics}
                mentorList={mentorList}
                filterKey={filterKey}
                handleOnFilter={handleOnFilter}
                dateTimeButtonList={["This Week", "This Month"]}
                isFilterChanged={isFilterChanged}
                user_detail={userDetail}
                filterStatus
                filterTopic
                filterDateTime
                filterMentor
              />
              <SortModal
                show={isSortModalOpened.toString()}
                handleClose={() => {
                  setSortModalOpened(false);
                }}
                handleOnSort={handleOnSort}
                sortKey={sortKey}
                user_detail={userDetail}
              />
              <Pagination
                handlePageClick={handlePageClick}
                pageCount={pageCount}
                itemOffset={itemOffset + 1}
                endOffset={itemOffset + perPage}
                totalItems={initialDataState.length}
              >
                <TableInternalComponent
                  table_data={paginatedData}
                  topics={topics}
                  onClickRow={handleOnClickRow}
                  setTableID={setTableID}
                />
              </Pagination>
            </InnerContainer>
          </OuterContainer>
          <ModalComponent
            show={scheduleModal}
            handleClose={handleShowModalFalse}
            body={
              isMentorAssigned
                ? [
                    <p style={{ fontWeight: 700 }} key={0}>
                      {table_ID_internal} has been successfully approved
                    </p>,
                    <p
                      style={{
                        fontWeight: 700,
                        fontSize: "25px",
                        color: "#007C7C",
                      }}
                      key={1}
                    >
                      Add online meeting link
                    </p>,
                    <p style={{ fontSize: "14px", color: "#00BB99" }} key={2}>
                      Step 2/2
                    </p>,
                    <textarea
                      key={3}
                      onChange={handleOnlineMeeting}
                      placeholder="Please input the online meeting link here..."
                      style={{
                        padding: "0.3em",
                        width: "70%",
                        minHeight: "150px",
                        border: "2px solid #00BB99",
                        borderRadius: "10px",
                        fontSize: "14px",
                      }}
                    />,
                    <div key={4}>
                      {isOnlineMeeting && (
                        <ModalSuccessAlert>
                          <img
                            src={assigned_mentor_icon}
                            alt="assigned_mentor_icon"
                            height={25}
                            width={25}
                            style={{ objectFit: "scale-down" }}
                          />
                          <ModalSuccessText>
                            Online meeting link successfully added
                          </ModalSuccessText>
                        </ModalSuccessAlert>
                      )}
                    </div>,
                    <ModalButtonContainer key={5}>
                      <ModalButtonCancel onClick={handleBackButton}>
                        Back
                      </ModalButtonCancel>
                      <ModalButton
                        onClick={handleSubmitButton}
                        disabled={!isOnlineMeeting}
                      >
                        Submit
                      </ModalButton>
                    </ModalButtonContainer>,
                  ]
                : [
                    <p style={{ fontWeight: 700 }} key={0}>
                      {table_ID_internal} has been successfully approved
                    </p>,
                    <p
                      style={{
                        fontWeight: 700,
                        fontSize: "25px",
                        color: "#007C7C",
                      }}
                      key={1}
                    >
                      Assign mentor
                    </p>,
                    <p style={{ fontSize: "14px", color: "#00BB99" }} key={2}>
                      Step 1/2
                    </p>,
                    <CustomDropdown
                      key={3}
                      value={assignedMentor}
                      placeholder={
                        combined_mentors_name.length > 0
                          ? "Select one of mentor available"
                          : "No mentor available for this topic"
                      }
                      padding="50px"
                      titleKey="assignedMentor"
                      options={combined_mentors_name}
                      action={(value) =>
                        handleChangeDropdown(value, "assignedMentor")
                      }
                      selectedKey="assignedMentor"
                      imageFile={add_mentor_icon}
                      disabled={combined_mentors_name.length <= 0}
                    />,
                    <div key={4}>
                      {assignedMentor && (
                        <ModalSuccessAlert>
                          <img
                            src={assigned_mentor_icon}
                            alt="assigned_mentor_icon"
                            height={25}
                            width={25}
                            style={{ objectFit: "scale-down" }}
                          />
                          <ModalSuccessText>
                            Succesfully assigned &quot;{assignedMentor}&quot; as
                            the mentor
                          </ModalSuccessText>
                        </ModalSuccessAlert>
                      )}
                    </div>,
                    <ModalButtonContainer key={5}>
                      <ModalButtonCancel onClick={handleShowModalFalse}>
                        Cancel
                      </ModalButtonCancel>
                      <ModalButton
                        onClick={handleNextModal}
                        disabled={
                          assignedMentor === "" ||
                          assignedMentor === undefined ||
                          assignedMentor === null
                        }
                      >
                        Next
                      </ModalButton>
                    </ModalButtonContainer>,
                  ]
            }
          />
          <ModalComponent
            show={approvalModal}
            handleClose={handleShowModalFalse}
            body={[
              <p style={{ fontWeight: 700 }} key={0}>
                Are you sure you want to approve {table_ID_internal}?
              </p>,
            ]}
            buttonPrimary="Yes"
            buttonSecondary="No"
            onPrimaryClick={handleApprovalPrimary}
            onSecondaryClick={handleApprovalSecondary}
          />
          <ModalComponent
            show={declineModal}
            handleClose={handleShowModalFalse}
            body={[
              <p style={{ fontWeight: 700 }} key={0}>
                Are you sure you want to decline {table_ID_internal}?{" "}
              </p>,
              <p style={{ fontWeight: 700 }} key={1}>
                <strong>The change can&apos; be reverted</strong>
              </p>,
            ]}
            buttonPrimary="Yes"
            buttonSecondary="No"
            onPrimaryClick={handleDeclinePrimary}
            onSecondaryClick={handleApprovalSecondary}
          />
        </>
      ) : (
        <div style={{ paddingLeft: "350px" }}>
          <div className="myBookingInnerContainerEmptySession">
            <img src={empty_session_1_icon} alt="empty_session_1_icon" />
            <h4 style={{ fontSize: "22px" }}>
              You have not made any booking yet
            </h4>
            <GreenButton
              width="180px"
              height="35px"
              textSize={font_size.title3}
              text="Book your first session"
              action={() => navigate("/coaching/book")}
              cursor="pointer"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default InternalBookings;

const OuterContainer = styled.div`
  padding-left: 350px;
`;

const InnerContainer = styled.div`
  padding: 130px 3vw 30px 3vw;
  text-align: left;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3em 2em 0em 2em;
`;

const ModalButtonCancel = styled.p`
  font-size: 14px;
  cursor: pointer;
`;

const ModalButton = styled.button`
  background: #00bb99;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.21);
  border-radius: 40px;
  border: none;
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;
  padding: 0.5em 1.5em;
`;

const ModalSuccessAlert = styled.div`
  display: flex;
  align-items: center;
  margin: 2em;
  font-weight: 700;
  padding: 1em;
  background: #89e9d7;
  text-align: left;
`;

const ModalSuccessText = styled.p`
  margin-left: 0.5em;
  margin-bottom: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const FilterSortButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: black solid 1px;
  background-color: #f0f8ff;
  color: black;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 16px;
  user-select: none;
  border-radius: 10px;
  :hover {
    filter: brightness(95%);
  }
  z-index: ${({ show }) => (show ? "2000" : "1")};
`;

import Axios from "../../helpers/axios";

export const createNewCoachingTopic = (body) => {
  return Axios.post(`/topics/create/new`, body);
};

export const updateCoachingTopic = (body, topic_id) => {
  return Axios.put(`/topics/update/${topic_id}`, body);
};

export const deleteCoachingTopic = (topic_id) => {
  return Axios.delete(`/topics/delete/${topic_id}`);
};

export const changeTopicActiveStatus = (topic_id, body) => {
  return Axios.patch(`/topics/active-status/${topic_id}`, body);
};

import React from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

// Components
import HeaderComponent from "../components/HeaderComponent";
import SidebarComponent from "../components/SidebarComponent";

// react icons
import { BsChevronRight } from "react-icons/bs";

// Styles
import "../styles/home.css";

// Helpers
import { device } from "../helpers/device";

// react-responsive
import { useMediaQuery } from "react-responsive";

// Images & Icons
import image_1 from "../assets/images/image-1.png";
import image_2 from "../assets/images/image-2.png";
import image_3 from "../assets/images/image-3.png";
import image_4 from "../assets/images/image-4.png";
import image_5 from "../assets/images/image-5.png";
import image_6 from "../assets/images/image-6.png";

const Home = () => {
  const navigate = useNavigate();

  const smallScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const mediumScreen = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1200px)",
  });

  return (
    <div>
      <HeaderComponent />
      <SidebarComponent />
      <div style={{ paddingLeft: smallScreen || mediumScreen ? "" : "350px" }}>
        <DashboardInnerContainer>
          <h1
            className="homePageTitle"
            style={{ fontWeight: smallScreen || mediumScreen ? "bold" : "" }}
          >
            Hello, welcome back!
          </h1>
          <div className="homeLineContainer">
            <div className="homeLatestCoachingContainer">
              <h5 className="homePageLabel">Your latest coaching session</h5>
              <div className="homeLatestCoachingCard">
                <div>
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "0",
                    }}
                  >
                    CCS-0131-0721
                  </div>
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "0",
                    }}
                  >
                    Topic: Entrepreneurship
                  </div>
                </div>
                <div
                  style={{
                    textAlign: "right",
                  }}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      justifyContent: "flex-end",
                    }}
                  >
                    23/07/21 15:00-16:00
                  </div>
                  <div>
                    <a
                      style={{
                        fontSize: "12px",
                        padding: 0,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      View details <BsChevronRight />
                    </a>
                  </div>
                </div>
              </div>
              <div className="homeLatestCoachingCard">
                <div>
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "0",
                    }}
                  >
                    CCS-0131-0721
                  </div>
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "0",
                    }}
                  >
                    Topic: Entrepreneurship
                  </div>
                </div>
                <div
                  style={{
                    textAlign: "right",
                  }}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      justifyContent: "flex-end",
                    }}
                  >
                    23/07/21 15:00-16:00
                  </div>
                  <div>
                    <a
                      style={{
                        fontSize: "12px",
                        padding: 0,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      View details <BsChevronRight />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <h5 className="homePageLabel">
                Upskill your knowledge with our world-class learning
              </h5>
              <div
                style={{
                  display: "flex",
                  flexWrap: smallScreen ? "wrap" : "",
                  width: "100%",
                  gap: 20,
                }}
              >
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      position: "relative",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        zIndex: 100,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        padding: "0 1rem",
                        height: "100%",
                      }}
                    >
                      <p
                        style={{
                          color: "white",
                          fontSize: 20,
                          fontWeight: "bold",
                          fontFamily: "Sen",
                        }}
                      >
                        Combined Clinics Sustainability
                      </p>
                      <p style={{ color: "#FFE7A3", fontSize: 16 }}>
                        for Corporate
                      </p>
                    </div>
                    <div className="homeImageContainerDimmed">
                      <img
                        src={image_1}
                        alt="image-1"
                        layout="fill"
                        style={{
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  </div>
                  <h5 className="homePageLabel" style={{ marginTop: "" }}>
                    CCS for Corporate
                  </h5>
                </div>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      position: "relative",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        zIndex: 100,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        padding: "0 1rem",
                        height: "100%",
                      }}
                    >
                      <p
                        style={{
                          color: "white",
                          fontSize: 20,
                          fontWeight: "bold",
                          fontFamily: "Sen",
                        }}
                      >
                        Combined Clinics Sustainability
                      </p>
                      <p style={{ color: "#FFE7A3", fontSize: 16 }}>
                        for Teams
                      </p>
                    </div>
                    <div className="homeImageContainerDimmed">
                      <img
                        src={image_1}
                        alt="image-1"
                        layout="fill"
                        style={{
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  </div>
                  <div className="homePageLabel">CCS for Teams</div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: smallScreen || mediumScreen ? "3em" : "",
              marginBottom: "3rem",
            }}
          >
            <h5
              className="homePageLabel"
              style={{
                fontWeight: smallScreen ? "bold" : "",
                fontSize: smallScreen ? "22px" : "",
              }}
            >
              Book your next coaching session
            </h5>
            <div className="homeLineContainer">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: mediumScreen ? "48%" : "100%",
                }}
              >
                <div className="homeImageContainer">
                  <img
                    src={image_1}
                    alt="image-1"
                    layout="fill"
                    style={{
                      objectFit: "cover",
                    }}
                  />
                </div>
                <h5 className="homePageLabel">Entrepreneurship</h5>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: mediumScreen ? "48%" : "100%",
                }}
              >
                <div className="homeImageContainer">
                  <img
                    src={image_2}
                    alt="image-2"
                    layout="fill"
                    style={{
                      objectFit: "cover",
                    }}
                  />
                </div>
                <h5 className="homePageLabel">
                  Social Return on Investment (SROI)
                </h5>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: mediumScreen ? "48%" : "100%",
                }}
              >
                <div className="homeImageContainer">
                  <img
                    src={image_3}
                    alt="image-3"
                    layout="fill"
                    style={{
                      objectFit: "cover",
                    }}
                  />
                </div>
                <h5 className="homePageLabel">Community Development</h5>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: mediumScreen ? "48%" : "100%",
                }}
              >
                <div className="homeImageContainer">
                  <img
                    src={image_4}
                    alt="image-4"
                    layout="fill"
                    style={{
                      objectFit: "cover",
                    }}
                  />
                </div>
                <h5 className="homePageLabel">Project Management</h5>
              </div>
            </div>
          </div>
          <h5 className="homePageLabel">Check out the free E-book for you</h5>
          <div className="homeLineContainer">
            <div style={{ width: mediumScreen ? "48%" : "100%" }}>
              <div className="imageContainerWide">
                <img src={image_5} alt="image_5" layout="fill" />
                <button
                  className="homePageDownloadButton"
                  onClick={() => navigate("/")}
                >
                  Download Now
                </button>
              </div>
            </div>
            <div style={{ width: mediumScreen ? "48%" : "100%" }}>
              <div className="imageContainerWide">
                <img src={image_6} alt="image_6" layout="fill" />
                <button
                  className="homePageDownloadButton"
                  onClick={() => navigate("/")}
                >
                  Download Now
                </button>
              </div>
            </div>
          </div>
        </DashboardInnerContainer>
      </div>
    </div>
  );
};

export default Home;

const DashboardInnerContainer = styled.div`
  padding: 80px 8vw 90px 8vw;
  text-align: left;
  @media ${device.tablet} {
    padding: 130px 3vw 90px 3vw;
  }
  @media ${device.laptop} {
    padding: 130px 3vw 90px 3vw;
  }
  @media ${device.laptopM} {
    padding: 130px 3vw 30px 3vw;
  }
  @media ${device.laptopL} {
    padding: 130px 3vw 30px 3vw;
  }
`;

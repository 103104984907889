function getServerURL() {
  const localhost = "http://localhost:4321";
  const staging = "https://ccs-api.cloudxier.dev";
  // const production = "https://ccs-production.cloudxier.com"; // change when production url already exist

  let host = window.location.host;
  if (host) {
    host = host.toLowerCase();
  }

  let env;
  switch (host) {
    case "ccs-staging.cloudxier.dev":
      env = staging;
      break;
    // case "ccs-production.cloudxier.com": // change when production url already exist
    //   env = staging;
    //   break;
    default:
      env = localhost;
  }
  return env;
}

export default getServerURL;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

// react-responsive
import { useMediaQuery } from "react-responsive";

// Styles
import "../styles/coachingBook.css";

// react-bootstrap
import Form from "react-bootstrap/Form";

// date fns
import { format } from "date-fns";

// lib
import font_size from "../lib/styling/font_size";

// Images & Icons
import ccs_logo_dark from "../assets/icons/ccs_logo_dark.png";
import arrow_left_icon_dark from "../assets/icons/arrow_left_icon_dark.png";

// react lottie
import Lottie from "react-lottie";
import loadingAnimatedIcon from "../static/loading-dots-blue.json";

// sweetalert
import Swal from "sweetalert2";

// Components
import SpinnerComponent from "../components/SpinnerComponent";
import {
  tabs,
  nonEntreForm,
  entreformInitialState,
  nonEntreformInitialState,
} from "../components/coaching/book/bookingForm";
import AlertTextBox from "../components/text_boxes/AlertTextBoxComponent";
import CustomDropdown from "../components/input/CustomDropdown";
import EntreForm from "../components/coaching/book/EntrepreneurshipForm";
import NonEntreForm from "../components/coaching/book/NonEntrepreneurshipForm";
import EntreFileUpload from "../components/coaching/book/EntrepreneurshipFileUpload";
import GreenButton from "../components/button/GreenButton";
import ModalComponent from "../components/ModalComponent";
import RouteChangeModal from "../components/RouteChangeModal";
import LoadingModalMobile from "../components/LoadingModalMobile";

// Apis
import {
  createNewSession,
  getDataBooking,
  updateOneSession,
  uploadFileSession,
} from "../apis/clientApis/sessions";
import { getAllTopics } from "../apis/topics";
import { getAllTimeMentorSchedules } from "../apis/clientApis/mentors";

const CoachingBook = () => {
  const navigate = useNavigate();
  const { booking_id } = useParams();
  const location = useLocation();

  const userDetail = useSelector((state) => state.userDetail);

  const [entreTopic, setEntreTopic] = useState(null);
  const [showRequiringDocumentAlert, setShowRequiringDocument] =
    useState(false);
  const [files, setFiles] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [formEdited, setFormEdited] = useState(false);
  const [formInitialState, setFormInitialState] = useState(
    showRequiringDocumentAlert
      ? entreformInitialState
      : nonEntreformInitialState
  );
  const [form, setForm] = useState(formInitialState);
  const [isTopicSelected, setIsTopicSelected] = useState(false);
  const [isError, setIsError] = useState(
    Object.values(form).some((el) => el && el.value && el.value === "")
  );
  const [coachingSessionId, setCoachingSessionId] = useState();
  const [bookingId, setBookingId] = useState(null);
  const [isTimeoutModal, setTimeoutModal] = useState(false);
  const [session, setSession] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isRouteChange, setIsRouteChange] = useState(false);
  const [schedules, setSchedules] = useState([]);
  const [topics, setTopics] = useState([]);

  let warnTimeout;
  let redirectTimeout;
  const [warningTime, setWarningTime] = useState(1 * 60 * 1000);
  const [redirectTime, setRedirectTime] = useState(2 * 60 * 1000);
  const [isTimeToRedirect, setTimeToRedirect] = useState(false);

  const smallScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const mediumScreen = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1200px)",
  });

  const clearTimeoutFunc = () => {
    if (warnTimeout) clearTimeout(warnTimeout);
    if (redirectTimeout) clearTimeout(redirectTimeout);
  };

  const resetTimeout = () => {
    if (location.pathname === "/coaching/book") {
      clearTimeoutFunc();
      runTimeout();
    }
  };

  async function getBookingData(booking_id) {
    setIsSubmitting(true);
    const { data } = await getDataBooking(booking_id);

    if (userDetail?.id === data.session.user_id) {
      const topic = topics.filter(
        (topic) => topic.required_documents.length > 0
      );
      setSession(data.session);

      if (data.session.topic_id === topic[0].id) {
        const booking_data = data.session
          ? Object.keys(data.session).filter((key) =>
              ["topic_id"].includes(key)
            )
          : "";
        const form_data = data.session.form_data
          ? Object.keys(data.session.form_data).filter(
              (key) => !["current_challenges"].includes(key)
            )
          : "";
        const form_keys = [...booking_data, ...form_data];
        const new_data = {};

        form_keys.forEach((key) => {
          if (key !== "createdAt" && key !== "updatedAt" && key !== "id") {
            if (key === "topic_id") {
              new_data["topic"] = {
                value: data.session[key],
              };
            } else {
              new_data[key] = {
                value: data.session[key] || data.session.form_data[key] || "",
              };
            }
          }
        });
        setForm({ ...entreformInitialState, ...new_data });
      } else {
        const exception = ["topic_id", "scheduled_date", "current_challenges"];
        const booking_data = data.session
          ? Object.keys(data.session).filter((key) => exception.includes(key))
          : "";
        const form_data = data.session.form_data
          ? Object.keys(data.session.form_data).filter((key) =>
              exception.includes(key)
            )
          : "";
        const form_keys = [...booking_data, ...form_data];
        const new_data = {};

        form_keys.forEach((key) => {
          if (key === "scheduled_date") {
            const scheduled_date = new Date(data.session[key]);

            new_data["scheduled_date"] = {
              value: scheduled_date,
            };
            if (Boolean(new Date(data.session[key]).getHours())) {
              new_data["time"] = {
                value: format(new Date(data.session[key]), "HH:mm"),
              };
            } else {
              new_data["time"] = {
                value: "",
              };
            }
          } else if (key === "topic_id") {
            new_data["topic"] = {
              value: data.session[key],
            };
          } else {
            new_data[key] = {
              value: data.session[key] || data.session.form_data[key] || "",
            };
          }
        });
        setForm({ ...nonEntreformInitialState, ...new_data });
      }
      setIsSubmitting(false);
    } else {
      setIsSubmitting(false);
      navigate("/coaching/book");
    }
  }

  async function updateSession(booking_id) {
    setIsSubmitting(true);
    let data = {
      topic_id: null,
      scheduled_date: null,
      duration: null,
      mentor_id: null,
      user_id: userDetail.id,
      form_data: {
        current_challenges: "",
      },
      document: "",
      feedback: "",
    };
    if (form.topic.value === entreTopic.id) {
      data.topic_id = form.topic.value;
      Object.keys(form).forEach((v) => {
        if (v !== "topic" && v !== "id") {
          data.form_data[v] = form[v].value;
        }
      });
      try {
        const { status, createdAt, updatedAt, user, ...sessionToSubmit } =
          session;
        const response = await updateOneSession(session.id, {
          ...sessionToSubmit,
          ...data,
        });
        if (response.status === 200) {
          setIsSubmitting(false);
          Swal.fire({
            customClass: {
              popup: "mentor-popup",
            },
            imageUrl:
              "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
            position: "top-end",
            text: "Successfully update session",
            width: "20em",
            heightAuto: "0.5em",
            showConfirmButton: false,
            timer: 1500,
          });
          setPageNumber((prev) => prev + 1);
          setCoachingSessionId(session.id);
          setBookingId(booking_id);
        }
        return response.status;
      } catch (error) {
        setIsSubmitting(false);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: error.toString(),
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } else {
      try {
        Object.keys(form).forEach((v) => {
          if (v === "current_challenges") {
            data.form_data[v] = form[v].value;
          } else if (v === "topic") {
            data.topic_id = form[v].value;
          } else if (v === "status") {
            data.status = "In review"; // Not important
          } else {
            data[v] = form[v].value;
          }
        });
        const formTime = form.time.value.split(":");
        const scheduled_date = new Date(data["scheduled_date"]);
        scheduled_date.setHours(formTime[0]);
        data["scheduled_date"] = scheduled_date.toLocaleString();
        const { time, ...dataToSubmit } = data;
        const { status, ...sessionToSubmit } = session;

        const response = await updateOneSession(session.id, {
          ...sessionToSubmit,
          ...dataToSubmit,
        });
        if (response.status === 200) {
          setIsSubmitting(false);
          Swal.fire({
            customClass: {
              popup: "mentor-popup",
            },
            imageUrl:
              "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
            position: "top-end",
            text: "Successfully update session",
            width: "20em",
            heightAuto: "0.5em",
            showConfirmButton: false,
            timer: 1500,
          });
          setPageNumber((prev) => prev + 1);
          setCoachingSessionId(session.id);
          setBookingId(booking_id);
          setTimeout(() => {
            setPageNumber((prev) => prev + 1);
          }, 1000);
        }
        return response.status;
      } catch (error) {
        setIsSubmitting(false);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: error.toString(),
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  }

  const runTimeout = () => {
    warnTimeout = setTimeout(() => setTimeoutModal(true), warningTime);
    redirectTimeout = setTimeout(() => setTimeToRedirect(true), redirectTime);
  };

  function handleCloseTimeoutModal() {
    setTimeoutModal(false);
    setTimeToRedirect(false);
  }

  function handleOnAutoCancel() {
    navigate("/");
  }

  async function createSession(form) {
    setIsSubmitting(true);
    let data = {
      topic_id: null,
      scheduled_date: null,
      duration: null,
      mentor_id: null,
      user_id: userDetail && userDetail.id,
      form_data: {
        current_challenges: "",
      },
      document: null,
      timeslot_id: null,
    };
    if (form.topic.value === entreTopic.id) {
      data.topic_id = form.topic.value;
      Object.keys(form).forEach((v) => {
        if (v !== "topic") {
          data.form_data[v] = form[v].value;
        }
      });
      try {
        const response = await createNewSession(data);
        if (response.status === 200) {
          setIsSubmitting(false);
          Swal.fire({
            customClass: {
              popup: "mentor-popup",
            },
            imageUrl:
              "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
            position: "top-end",
            text: "Successfully create new session",
            width: "20em",
            heightAuto: "0.5em",
            showConfirmButton: false,
            timer: 1500,
          });
          setPageNumber((prev) => prev + 1);
          setCoachingSessionId(response.data.newCoachingSession.id);
          setBookingId(response.data.newCoachingSession.booking_id);
        }
        return response.status;
      } catch (error) {
        setIsSubmitting(false);
        // openSnackbar(error);
      }
    } else {
      try {
        Object.keys(form).forEach((v) => {
          if (v === "current_challenges") {
            data.form_data[v] = form[v].value;
          } else if (v === "topic") {
            data.topic_id = form[v].value;
          } else if (v === "status") {
            data.status = "In review"; // Not important
          } else if (v === "timeslot_id") {
            data.timeslot_id = form[v];
          } else {
            data[v] = form[v].value;
          }
        });
        const formTime = form.time.value.split(":");
        const scheduled_date = new Date(data["scheduled_date"]);
        scheduled_date.setHours(formTime[0]);
        data["scheduled_date"] = scheduled_date.toLocaleString();
        const { time, ...dataToSubmit } = data;
        const response = await createNewSession(dataToSubmit);
        if (response.status === 200) {
          setIsSubmitting(false);
          Swal.fire({
            customClass: {
              popup: "mentor-popup",
            },
            imageUrl:
              "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
            position: "top-end",
            text: "Successfully create new session",
            width: "20em",
            heightAuto: "0.5em",
            showConfirmButton: false,
            timer: 1500,
          });
          setPageNumber((prev) => prev + 1);
          setCoachingSessionId(response.data.newCoachingSession.id);
          setBookingId(response.data.newCoachingSession.booking_id);
          setTimeout(() => {
            setPageNumber((prev) => prev + 1);
          }, 1000);
        }
        return response.status;
      } catch (error) {
        setIsSubmitting(false);
      }
    }
  }

  async function uploadFile(file) {
    setIsSubmitting(true);
    try {
      const formData = new FormData();
      formData.append("document", file);
      const result = await uploadFileSession(coachingSessionId, formData);
      if (result) {
        setIsSubmitting(false);
        Swal.fire({
          customClass: {
            popup: "mentor-popup",
          },
          imageUrl:
            "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
          position: "top-end",
          text: "Booking Success",
          width: "20em",
          heightAuto: "0.5em",
          showConfirmButton: false,
          timer: 1500,
        });
        setPageNumber(pageNumber + 1);
        removeFormData();
      }
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
    }
  }

  function handleChange(e, key) {
    if (e.target.value !== "") {
      setForm({
        ...form,
        [key]: { ...form[key], value: e.target.value, error: false },
      });
    } else {
      if (form[key].required) {
        setForm({
          ...form,
          [key]: { ...form[key], value: e.target.value, error: true },
        });
      } else {
        setForm({
          ...form,
          [key]: { ...form[key], value: e.target.value, error: false },
        });
      }
    }
    setFormEdited(true);
    setIsError(
      Object.values(form).some((el) => el && el.required && el.value === "")
    );
  }

  function handleOnClickAccordion(index) {
    if (Object.values(form).some((el) => el.value === "")) {
      let tempForm = { ...form };
      if (index === 1) {
        tabs[0].body.map((item) => {
          if (
            form[item.value]?.required &&
            !Boolean(form[item.value]?.value || "")
          ) {
            tempForm[item.value] = { ...tempForm[item.value], error: true };
          }
        });
      } else if (index === 2) {
        tabs[1].body.map((item) => {
          if (
            form[item.value]?.required &&
            !Boolean(form[item.value]?.value || "")
          ) {
            tempForm[item.value] = { ...tempForm[item.value], error: true };
          }
        });
      }
      setForm(tempForm);
    }
  }

  function checkReqDocsAlert() {
    topics.forEach((eachTopic) => {
      if (form["topic"].value === eachTopic.id) {
        if (eachTopic.required_documents.length > 0) {
          setShowRequiringDocument(true);
        } else {
          setShowRequiringDocument(false);
        }
      }
    });
  }

  function handleChangeDropdown(value, key) {
    if (key === "topic") {
      if (value === entreTopic.id) {
        if (booking_id) {
          setForm({
            ...form,
            topic: { ...form[key], value: value, error: false },
          });
        } else {
          setForm({
            ...entreformInitialState,
            topic: { ...form[key], value: value, error: false },
          });
        }
        setPageNumber(0);
      } else {
        if (booking_id) {
          setForm({
            ...form,
            topic: { ...form[key], value: value, error: false },
          });
        } else {
          setForm({
            ...nonEntreformInitialState,
            topic: { ...form[key], value: value, error: false },
          });
        }
        setPageNumber(0);
      }
    } else {
      setForm({ ...form, [key]: { ...form[key], value: value, error: false } });
    }
    checkReqDocsAlert();
  }

  function removeFormData() {
    window.localStorage.removeItem("formData");
  }

  function updateData() {
    // removeFormData()
    // window.localStorage.setItem('formData', JSON.stringify(form))
  }

  async function handleSaveAndExit() {
    setIsSubmitting(false);
    if (pageNumber === 0) {
      if (booking_id) {
        const statusCode = await updateSession(booking_id);
        setPageNumber((prev) => prev - 1);
        if (statusCode === 200) {
          setIsSubmitting(false);
          Swal.fire({
            customClass: {
              popup: "mentor-popup",
            },
            imageUrl:
              "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
            position: "top-end",
            text: "Successfully update session",
            width: "20em",
            heightAuto: "0.5em",
            showConfirmButton: false,
            timer: 1500,
          });
          navigate("/my-booking");
          removeFormData();
        } else {
          setIsSubmitting(false);
        }
      } else {
        const statusCode = await createSession(form);
        setPageNumber((prev) => prev - 1);
        if (statusCode === 200) {
          setIsSubmitting(false);
          Swal.fire({
            customClass: {
              popup: "mentor-popup",
            },
            imageUrl:
              "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
            position: "top-end",
            text: "Successfully create new session",
            width: "20em",
            heightAuto: "0.5em",
            showConfirmButton: false,
            timer: 1500,
          });
          navigate("/my-booking");
          removeFormData();
        } else {
          setIsSubmitting(false);
        }
      }
    }
  }

  function handleBackButtonClicked() {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
    } else {
      setIsRouteChange(true);
      // router.back()
      // removeFormData()
    }
  }

  function handleButtonPrimaryRouteChange() {
    navigate(-1);
    removeFormData();
  }

  function handleButtonSecondaryRouteChange() {
    setIsRouteChange(false);
  }

  async function handleNextButtonClicked() {
    if (isError) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Please fill all required inputs",
        showConfirmButton: false,
        timer: 1500,
      });
    } else if (isSubmitting) {
      Swal.fire({
        position: "top-end",
        icon: "info",
        title: "Please wait for a moment",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      if (form.topic.value === entreTopic.id) {
        if (pageNumber === 0) {
          setIsSubmitting(true);
          if (booking_id) {
            await updateSession(booking_id);
          } else {
            await createSession(form);
          }
          setIsSubmitting(false);
        } else if (pageNumber === 1) {
          setIsSubmitting(true);
          if (files?.Proposal?.length > 0) {
            uploadFile(files.Proposal[0]);
          } else {
            Swal.fire({
              position: "top-end",
              icon: "info",
              title: "Please upload your proposal",
              showConfirmButton: false,
              timer: 1500,
            });
          }
          setIsSubmitting(false);
        } else if (pageNumber === 2) {
          navigate("/my-booking");
        }
      } else {
        if (pageNumber === 0) {
          setIsSubmitting(true);
          if (booking_id) {
            await updateSession(booking_id);
          } else {
            await createSession(form);
          }
          setIsSubmitting(false);
        } else if (pageNumber === 1) {
          return false;
        } else if (pageNumber === 2) {
          navigate("/my-booking");
        }
      }
    }
  }

  function handleBlur() {}

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimatedIcon,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  function renderSessionForm() {
    if (form.topic.value === entreTopic.id) {
      if (pageNumber === 0) {
        return (
          <EntreForm
            tabs={tabs}
            form={form}
            handleChange={handleChange}
            handleBlur={handleBlur}
            updateData={updateData}
            handleChangeDropdown={handleChangeDropdown}
            handleOnClickAccordion={handleOnClickAccordion}
          />
        );
      } else if (pageNumber === 1) {
        const topic = topics.filter((topic) => topic.id == form["topic"].value);
        return (
          <>
            {topic[0].required_documents.map((document, index) => (
              <EntreFileUpload
                document={document}
                key={index}
                files={files}
                setFiles={setFiles}
              />
            ))}
          </>
        );
      } else if (pageNumber === 2) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              height: "100%",
            }}
          >
            <p>
              Thank you for booking a coaching session at Combined Clinics
              Sustainability
            </p>
            <p style={{ fontWeight: "bold" }}>Booking ID: {bookingId}</p>
            <p style={{ fontWeight: "bold" }}>
              Topic:{" "}
              {
                topics.filter((topic) => topic.id === form.topic.value)?.[0]
                  ?.title
              }
            </p>
            <p>
              Our team will assess and review your documents. We will send
              further instruction to your email. Please screenshot this page as
              a proof.
            </p>
            <GreenButton
              action={handleNextButtonClicked}
              width="200px"
              height="40px"
              textSize={font_size.title2}
              text="Okay, got it"
              bgcolor="#00BB99"
            />
          </div>
        );
      }
    } else {
      if (pageNumber === 0) {
        return (
          <NonEntreForm
            nonEntreForm={nonEntreForm}
            handleChange={handleChange}
            form={form}
            setForm={setForm}
            handleBlur={handleBlur}
            setFormEdited={setFormEdited}
            setIsError={setIsError}
            scheduleData={schedules}
            smallScreen={smallScreen}
          />
        );
      } else if (pageNumber === 1) {
        return (
          <div>
            <Lottie options={defaultOptions} height={125} width={260} />
            <p style={{ textAlign: "center" }}>
              Please wait, we are processing your booking request..
            </p>
          </div>
        );
      } else if (pageNumber === 2) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              height: "100%",
            }}
          >
            <p>
              Thank you for booking a coaching session at Combined Clinics
              Sustainability
            </p>
            <p style={{ fontWeight: "bold" }}>Booking ID: {bookingId}</p>
            <p style={{ fontWeight: "bold" }}>
              Topic:{" "}
              {
                topics.filter((topic) => topic.id === form.topic.value)?.[0]
                  ?.title
              }
            </p>
            <p>
              You will receive email confirmation on your upcoming session
              details. Please screenshot this page as a proof.
            </p>
            <GreenButton
              action={handleNextButtonClicked}
              width="200px"
              height="40px"
              textSize={font_size.title2}
              text="Okay, got it"
              bgcolor="#00BB99"
            />
          </div>
        );
      }
    }
  }

  const handleGetAllTopics = async () => {
    setIsSubmitting(true);
    const result = await getAllTopics();
    if (result && result.data) {
      let finalTopics = result.data;
      finalTopics =
        finalTopics && Array.isArray(finalTopics) && finalTopics.length > 0
          ? finalTopics.filter((topic) => topic.is_active === true)
          : [];
      setTopics(finalTopics);
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (booking_id) {
      getBookingData(booking_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking_id]);

  useEffect(() => {
    setIsError(
      Object.values(form).some((el) => el && el.required && el.value === "")
    );
  }, [form]);

  useEffect(() => {
    setIsError(
      Object.values(form).some((el) => el && el.required && el.value === "")
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const topic = topics.filter((topic) => topic.required_documents.length > 0);
    setEntreTopic(topic[0]);
  }, [topics]);

  // Fetch available mentor schedules from chosen topic
  useEffect(() => {
    async function fetchSchedules() {
      if (form && form["topic"] && form["topic"].value) {
        try {
          const params = { topic_id: form["topic"].value };
          const response = await getAllTimeMentorSchedules(params);
          setSchedules(response.data);
        } catch (err) {
          console.log(err);
        }
      }
    }
    fetchSchedules();
  }, [form]);

  useEffect(() => {
    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];
    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }
    runTimeout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleGetAllTopics();
  }, []);

  //   useEffect(() => {
  //     // const formData = JSON.parse(window.localStorage.getItem("formData"))
  //     // if (formData) {
  //     //   setForm(formData)
  //     // } else {
  //     //   if (showRequiringDocumentAlert) {
  //     //     setForm(entreformInitialState)
  //     //   } else {
  //     //     setForm(nonEntreformInitialState)
  //     //   }
  //     // }
  //   }, [formInitialState, showRequiringDocumentAlert]);

  //   useEffect(() => {
  //     // removeFormData()
  //     // window.localStorage.setItem('formData', JSON.stringify(form))
  //   }, [form]);

  //   useEffect(() => {
  //     if (isTimeToRedirect) {
  //       navigate("/");
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [isTimeToRedirect]);

  //   useEffect(() => {
  //     router.events.on("routeChangeStart", clearTimeoutFunc);
  //     return () => {
  //       router.events.off("routeChangeStart", clearTimeoutFunc);
  //     };
  //   });

  return (
    <div className="coachingBookMainContainer">
      <SpinnerComponent
        isShown={isSubmitting && !smallScreen && !mediumScreen}
        right="20px"
        top="20px"
      />
      <div
        onClick={() => navigate("/")}
        className="coachingBookLogoCCSContainer"
      >
        <img src={ccs_logo_dark} alt="ccs_logo_dark" />
      </div>
      {isSubmitting && (smallScreen || mediumScreen) ? (
        <LoadingModalMobile />
      ) : (
        <div className="coachingBookFlexContainer">
          <div className="coachingBookCenteredBox">
            <div style={{ height: "100%", textAlign: "left" }}>
              {pageNumber !== 2 && (
                <>
                  <div
                    onClick={handleBackButtonClicked}
                    style={{
                      color: "black",
                      fontSize: font_size.title3,
                      userSelect: "none",
                      display: "flex",
                      gap: "0.25vw",
                    }}
                  >
                    <img
                      src={arrow_left_icon_dark}
                      className="coachingBookArrowLeft"
                    />
                    <div
                      style={{
                        color: "black",
                        fontSize: font_size.title3,
                        userSelect: "none",
                      }}
                    >
                      Back
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: font_size.title1,
                      color: "#00BB99",
                      fontWeight: "bold",
                      marginBottom: "20px",
                      paddingTop: "2.5vh",
                    }}
                  >
                    Book a session
                  </div>
                </>
              )}
              {entreTopic &&
              entreTopic.id &&
              form.topic.value === entreTopic.id &&
              pageNumber !== 2 ? (
                <AlertTextBox
                  title="What you need to prepare"
                  text={[
                    "Before choosing the schedule availability, this topic require a proposal submission. To view the step-by-step instructions and details requirements",
                  ]}
                  button={
                    <div
                      style={{ cursor: "pointer", color: "blue" }}
                      onClick={() => navigate("/coaching/book/requirements")}
                    >
                      <u>click here</u>
                    </div>
                  }
                  bgcolor="#FCDCD9"
                />
              ) : (
                pageNumber === 0 && (
                  <AlertTextBox
                    text={[
                      "Mentor will be assigned by our system based on the availability.",
                    ]}
                    bgcolor="#E5E5E5"
                  />
                )
              )}
              {pageNumber === 0 ||
              (pageNumber === 1 && form.topic.value === entreTopic.id) ? (
                <div style={{ marginBottom: "20px" }}>
                  <Form.Group>
                    <div
                      style={{
                        marginBottom: "5px",
                        fontSize: font_size.title2,
                        fontWeight: "bold",
                      }}
                    >
                      Topic of interest
                    </div>
                    <CustomDropdown
                      value={form["topic"].value}
                      placeholder="Choose topic"
                      onSelect={updateData}
                      options={topics}
                      titleKey="title"
                      action={(value) => handleChangeDropdown(value, "topic")}
                      isSelected={form["topic"].value}
                    />
                  </Form.Group>
                </div>
              ) : null}
              {form["topic"].value && renderSessionForm()}
            </div>
            <div className="coachingBookBottomBar">
              {((entreTopic &&
                entreTopic.id &&
                form.topic.value === entreTopic.id) ||
                pageNumber !== 1) &&
              pageNumber !== 2 &&
              formEdited ? (
                <div
                  onClick={handleSaveAndExit}
                  className="coachingBookCancelButton"
                >
                  Save and Exit
                </div>
              ) : pageNumber === 0 ? (
                <div
                  onClick={handleBackButtonClicked}
                  className="coachingBookCancelButton"
                >
                  Cancel
                </div>
              ) : (
                <div></div>
              )}
              {((entreTopic &&
                entreTopic.id &&
                form.topic.value === entreTopic.id) ||
                pageNumber !== 1) &&
                pageNumber !== 2 && (
                  <GreenButton
                    action={handleNextButtonClicked}
                    width="85px"
                    height="30px"
                    textSize={font_size.title3}
                    text="Next"
                    bgcolor="#00BB99"
                    opacity={
                      isSubmitting ||
                      isError ||
                      (pageNumber === 1 && !Boolean(files?.Proposal?.length))
                        ? 0.3
                        : 1
                    }
                    cursor={
                      isSubmitting ||
                      isError ||
                      (pageNumber === 1 && !Boolean(files?.Proposal?.length))
                        ? "default"
                        : "pointer"
                    }
                    isError={
                      isSubmitting ||
                      isError ||
                      (pageNumber === 1 && !Boolean(files?.Proposal?.length))
                    }
                    disabled={isSubmitting}
                  />
                )}
            </div>
          </div>
        </div>
      )}
      <ModalComponent
        show={isTimeoutModal}
        handleClose={handleCloseTimeoutModal}
        body={[
          <p style={{ fontWeight: 700 }} key={0}>
            Are you still with us?
          </p>,
          <p style={{ fontWeight: 500 }} key={1}>
            Your session is about to expire, do you need more time?
          </p>,
          <p key={2}>
            Session will be automatically cancelled in{" "}
            <span style={{ fontWeight: 700 }}> 60 seconds</span>
          </p>,
        ]}
        closeButton={false}
        buttonPrimary="Add more time"
        buttonSecondary="Cancel"
        onPrimaryClick={handleCloseTimeoutModal}
        onSecondaryClick={handleOnAutoCancel}
      />
      <RouteChangeModal
        show={isRouteChange}
        handleClose={handleButtonSecondaryRouteChange}
        body={[
          <p style={{ fontWeight: 700 }} key={0}>
            Are you sure you want to leave this page?
          </p>,
          <p key={0}>Changes you made will not be saved.</p>,
        ]}
        closeButton={false}
        buttonPrimary="Yes"
        buttonSecondary="No"
        onPrimaryClick={handleButtonPrimaryRouteChange}
        onSecondaryClick={handleButtonSecondaryRouteChange}
      />
    </div>
  );
};

export default CoachingBook;

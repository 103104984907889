import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";

// react bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Images and Icons
import arrow_left_icon_dark from "../assets/icons/arrow_left_icon_dark.png";
import dropdown_navy_icon from "../assets/icons/dropdown_navy_icon.svg";

// react-collapsible
import Collapsible from "react-collapsible";

// HTML react parser
import parse from "html-react-parser";

// Components
import HeaderComponent from "../components/HeaderComponent";
import SpinnerComponent from "../components/SpinnerComponent";
import CourseRecapDetailCoursePage from "../components/CourseRecapDetailCoursePage";
import SidebarComponent from "../components/SidebarComponent";
import LoadingModalMobile from "../components/LoadingModalMobile";

// Apis
import { getDetailCourse } from "../apis/courses";
import {
  getOneStudentCourseLessonVideo,
  updateLessonLastWatchedVideoInSecond,
  updateStudentCourseLessonVideoIsFinishWatching,
  updateStudentLastActivityStudentCourse,
} from "../apis/clientApis/courses";

// Helpers
import getTimeFromSeconds from "../helpers/get-time-from-seconds";
import { device } from "../helpers/device";

// api video player library
import ApiVideoPlayer from "@api.video/react-player";

// sweetalert
import Swal from "sweetalert2";

// react-responsive
import { useMediaQuery } from "react-responsive";

function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}

const CourseDetail = () => {
  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();
  const { course_id } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [courseDetails, setCourseDetails] = useState(null);
  const [courseSections, setCourseSections] = useState([]);
  const [infoName, setInfoName] = useState(
    "what_will_you_learn_in_this_course"
  );

  const smallScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const mediumScreen = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1200px)",
  });

  // Handle back button
  function backToCourseList() {
    return navigate(-1);
  }

  const findFinishWatchingForEveryLesson = (arrLessons) => {
    let flagger = arrLessons.some(
      (lesson) => !lesson.is_finish_watching === false
    );
    return !flagger;
  };

  const handleFetchCourseDetail = async (
    course_id,
    isShowNotification,
    prop_course_section_id
  ) => {
    setLoading(true);
    const result = await getDetailCourse(course_id);
    if (result && result.data) {
      handleUpdateStudentLastActivityStudentCourse(result.data.id);
      setCourseDetails(result.data);
      let finalCourseSections = result.data.course_sections;
      for (let fcs = 0; fcs < finalCourseSections.length; fcs++) {
        const { lessons } = finalCourseSections[fcs];
        const course_section_id = finalCourseSections[fcs]["id"];
        for (let l = 0; l < lessons.length; l++) {
          const lesson_id = lessons[l]["id"];
          if (
            lessons[l] &&
            lessons[l]["content_type"] &&
            lessons[l]["content_type"] === "video"
          ) {
            let foundCourseLessonVideo = await getOneStudentCourseLessonVideo(
              course_id,
              course_section_id,
              lesson_id
            );
            if (
              foundCourseLessonVideo &&
              foundCourseLessonVideo.data &&
              foundCourseLessonVideo.data.last_video_watched_in_seconds
            ) {
              finalCourseSections[fcs]["lessons"][l] = {
                ...finalCourseSections[fcs]["lessons"][l],
                last_video_watched_in_seconds:
                  foundCourseLessonVideo.data.last_video_watched_in_seconds,
                is_finish_watching:
                  foundCourseLessonVideo.data.is_finish_watching,
              };
            }
          }
        }

        let isThereUnfinishedLessonVideoWatched = false;
        if (fcs !== 0) {
          isThereUnfinishedLessonVideoWatched =
            findFinishWatchingForEveryLesson(
              finalCourseSections[fcs - 1]["lessons"] // -1 cause of we need to check last lessons of last course sections
            );
        }

        finalCourseSections[fcs] = {
          ...finalCourseSections[fcs],
          isDisableOpenAccordion:
            fcs === 0 ? false : isThereUnfinishedLessonVideoWatched,
        };

        if (
          isShowNotification &&
          finalCourseSections[fcs]["isDisableOpenAccordion"] &&
          prop_course_section_id &&
          +prop_course_section_id === +course_section_id
        ) {
          Swal.fire({
            position: "top-end",
            icon: "info",
            text: "Complete the previous lesson to continue.",
            showConfirmButton: false,
            timer: 3000,
          });
        }
      }
      // finalCourseSections =
      //   finalCourseSections &&
      //   Array.isArray(finalCourseSections) &&
      //   finalCourseSections.length > 0
      //     ? finalCourseSections.map((eachCourseSection) => {
      //         let { lessons } = eachCourseSection || {};
      //         let finalLessons = lessons;
      //         if (
      //           finalLessons &&
      //           Array.isArray(finalLessons) &&
      //           finalLessons.length > 0
      //         ) {
      //           finalLessons = finalLessons.map((eachLesson) => {
      //             eachLesson["videoRef"] = useRef(null);
      //             return eachLesson;
      //           });
      //         }
      //         eachCourseSection = {
      //           ...eachCourseSection,
      //           lessons: finalLessons,
      //         };
      //         return eachCourseSection;
      //       })
      //     : [];
      setCourseSections(finalCourseSections);
      forceUpdate();
    }
    setLoading(false);
  };

  const handleUpdateStudentLastActivityStudentCourse = async (course_id) => {
    await updateStudentLastActivityStudentCourse(course_id);
  };

  const getTotalDurationCourse = (course) => {
    const { course_sections } = course || {};
    let totalDurationInSeconds = 0;
    for (let cs = 0; cs < course_sections.length; cs++) {
      const { total_course_duration_in_seconds } = course_sections[cs];
      totalDurationInSeconds += total_course_duration_in_seconds;
    }
    totalDurationInSeconds = getTimeFromSeconds(totalDurationInSeconds);
    totalDurationInSeconds = `${
      totalDurationInSeconds.hours
        ? `${
            totalDurationInSeconds.hours > 1
              ? `${totalDurationInSeconds.hours} hours`
              : `${totalDurationInSeconds.hours} hour`
          }`
        : ``
    } ${
      totalDurationInSeconds.minutes
        ? `${
            totalDurationInSeconds.minutes > 1
              ? `${totalDurationInSeconds.minutes} mins`
              : `${totalDurationInSeconds.minutes} min`
          }`
        : ``
    } ${
      !totalDurationInSeconds.hours && totalDurationInSeconds.seconds
        ? `${
            totalDurationInSeconds.seconds > 1
              ? `${totalDurationInSeconds.seconds} secs`
              : `${totalDurationInSeconds.seconds} sec`
          }`
        : ``
    }`;
    return totalDurationInSeconds;
  };

  // Options renderer
  function renderOptions() {
    if (infoName === "what_will_you_learn_in_this_course") {
      return <div>{parse(courseDetails?.what_you_will_learn)}</div>;
    } else if (infoName === "target_audience") {
      return <div>{parse(courseDetails?.target_audience)}</div>;
    } else if (infoName === "additional_description") {
      return <div>{parse(courseDetails?.additional_description)}</div>;
    } else {
      return (
        <div>
          <p>Data not found, please try another options...</p>
        </div>
      );
    }
  }

  const handleOnPauseVideo = async (
    course_id,
    course_section_id,
    lesson_id,
    videoRefCurrentTime
  ) => {
    const result = await updateLessonLastWatchedVideoInSecond(
      course_id,
      course_section_id,
      lesson_id,
      {
        last_video_watched_in_seconds: videoRefCurrentTime,
      }
    );
    if (result && result.data && result.data.success) {
      await handleFetchCourseDetail(course_id, false, course_section_id);
    }
  };

  const handleOnVideoEnded = async (
    course_id,
    course_section_id,
    lesson_id
  ) => {
    const result = await updateStudentCourseLessonVideoIsFinishWatching(
      course_id,
      course_section_id,
      lesson_id,
      {
        is_finish_watching: true,
      }
    );
    if (result && result.data && result.data.success) {
      await handleFetchCourseDetail(course_id, false, course_section_id);
    }
  };

  const handleShowNotification = async (course_section_id) => {
    await handleFetchCourseDetail(course_id, true, course_section_id);
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    if (course_id) {
      handleFetchCourseDetail(course_id);
    }
  }, [course_id]);

  return (
    <div>
      <HeaderComponent />
      <SidebarComponent />
      <SpinnerComponent
        isShown={isLoading && !smallScreen && !mediumScreen}
        right="20px"
        top="20px"
      />
      {isLoading && (smallScreen || mediumScreen) ? (
        <LoadingModalMobile />
      ) : (
        <OuterContainer>
          <InnerContainer>
            {!smallScreen ? (
              <>
                <Row style={{ margin: 0 }}>
                  <BackNavigation onClick={backToCourseList}>
                    <img
                      src={arrow_left_icon_dark}
                      alt="arrow_left_icon_dark"
                      height={15}
                      width={15}
                      style={{
                        objectFit: "scale-down",
                        objectPosition: "left bottom",
                      }}
                    />
                    {`    Back`}
                  </BackNavigation>
                </Row>
                <Row style={{ marginBottom: 20 }}>
                  <Col>
                    <PageTitle>Course Details</PageTitle>
                  </Col>
                </Row>
              </>
            ) : null}
            {courseDetails ? (
              <>
                <Row>
                  <Col
                    style={{
                      marginBottom: "2vh",
                      gap: "10vw",
                      // width: "70%",
                    }}
                  >
                    {smallScreen ? null : (
                      <div
                        style={{
                          // width: "384px",
                          // height: "254px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: "4vh",
                        }}
                      >
                        <img
                          src={courseDetails?.course_image_url}
                          style={{
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: smallScreen ? "100%" : "75%",
                            height: smallScreen ? "auto" : "500px",
                            // maxWidth: "384px",
                            // maxHeight: "254px",
                            // width: "100%",
                            // height: "53.5vh",
                            objectFit: "cover",
                            borderRadius: "30px",
                          }}
                          alt="courseImage"
                        />
                      </div>
                    )}
                    {courseDetails && courseDetails.course_video_id ? (
                      <div
                        style={{
                          // width: "384px",
                          // height: "254px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: "4vh",
                        }}
                      >
                        <ApiVideoPlayer
                          video={{ id: courseDetails.course_video_id }}
                          style={{
                            width: smallScreen ? "100%" : "75%",
                            height: smallScreen ? "300px" : "500px",
                            objectFit: "cover",
                            borderRadius: "15px",
                          }}
                        />
                      </div>
                    ) : null}
                    {/* <div
                    style={{
                      // width: "42.5%",
                      // width: "384px",
                      // height: "254px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "2vh",
                    }}
                  >
                    <video
                      style={{
                        width: "100%",
                        // height: "53.5vh",
                        // width: "384px",
                        // height: "254px",
                        borderRadius: "30px",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                      }}
                      controls={true}
                    >
                      <source
                        src={courseDetails?.course_video_highlight_url}
                        type="video/mp4"
                      />
                    </video>
                  </div> */}
                  </Col>
                </Row>
                <Col>
                  <TextTitle>
                    {capitalizeFirstLetter(courseDetails?.course_name)}
                  </TextTitle>
                  <Text>{getTotalDurationCourse(courseDetails)}</Text>
                  <Col>
                    <Text>{courseDetails?.course_summary}</Text>
                  </Col>
                </Col>
                <div
                  style={{
                    display: "flex",
                    flexDirection: smallScreen ? "column" : "row",
                  }}
                >
                  <Col>
                    <Label>Course type</Label>
                    <Text>{courseDetails?.course_type}</Text>
                  </Col>
                  <Col>
                    <Label>Course length</Label>
                    <Text>{courseDetails?.course_length}</Text>
                  </Col>
                  <Col>
                    <Label>Course language</Label>
                    <Text>{courseDetails?.course_language}</Text>
                  </Col>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: smallScreen ? "column" : "row",
                  }}
                >
                  <Col>
                    <Label>Course level</Label>
                    <Text>{courseDetails?.course_level}</Text>
                  </Col>
                  <Col>
                    <Label>Course category</Label>
                    <Text>{courseDetails?.course_category}</Text>
                  </Col>
                  <Col>
                    <Label>Course sub-category</Label>
                    <Text>{courseDetails?.course_sub_category}</Text>
                  </Col>
                </div>
                <Row>
                  <Col>
                    <Label>Assigned instructor</Label>
                    <Text>{`${
                      courseDetails?.assigned_instructor?.first_name || ""
                    } ${
                      courseDetails?.assigned_instructor?.last_name || ""
                    }`}</Text>
                  </Col>
                </Row>
                {smallScreen || mediumScreen ? (
                  <>
                    <Row style={{ marginBottom: "0.5em" }}>
                      <Col>
                        <Label> What will you learn in this course ?</Label>
                        <Text>{parse(courseDetails?.what_you_will_learn)}</Text>
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: "0.5em" }}>
                      <Col>
                        <Label>Target Audience</Label>
                        <Text>{parse(courseDetails?.target_audience)}</Text>
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: "0.5em" }}>
                      <Col>
                        <Label>Additional description</Label>
                        <Text>
                          {parse(courseDetails?.additional_description)}
                        </Text>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <MentorOptions>
                      <MentorOptionSelect
                        className={infoName}
                        match="what_will_you_learn_in_this_course"
                        onClick={() =>
                          setInfoName("what_will_you_learn_in_this_course")
                        }
                      >
                        <MentorOptionText>
                          What will you learn in this course ?
                        </MentorOptionText>
                        <MentorOptionLine
                          className={infoName}
                          match="what_will_you_learn_in_this_course"
                        />
                      </MentorOptionSelect>
                      <MentorOptionSelect
                        className={infoName}
                        match="target_audience"
                        onClick={() => setInfoName("target_audience")}
                      >
                        <MentorOptionText>Target Audience</MentorOptionText>
                        <MentorOptionLine
                          className={infoName}
                          match="target_audience"
                        />
                      </MentorOptionSelect>
                      <MentorOptionSelect
                        className={infoName}
                        match="additional_description"
                        onClick={() => setInfoName("additional_description")}
                      >
                        <MentorOptionText>
                          Additional description
                        </MentorOptionText>
                        <MentorOptionLine
                          className={infoName}
                          match="additional_description"
                        />
                      </MentorOptionSelect>
                    </MentorOptions>
                    <MentorInvisibleLine />
                    <div style={{ marginBottom: "5vh" }}>{renderOptions()}</div>
                  </>
                )}
                {courseSections &&
                Array.isArray(courseSections) &&
                courseSections.length > 0
                  ? courseSections.map((section, index) => {
                      const {
                        id,
                        course_section_name,
                        lessons,
                        isDisableOpenAccordion,
                      } = section || {};
                      return (
                        <Collapsible
                          key={index}
                          trigger={
                            <CollapsibleTrigger
                              onClick={() =>
                                isDisableOpenAccordion
                                  ? handleShowNotification(id)
                                  : null
                              }
                            >
                              {smallScreen ? (
                                <div
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div>
                                    <div style={{ color: "#002b73" }}>
                                      {course_section_name
                                        ? course_section_name
                                            .charAt(0)
                                            .toUpperCase() +
                                          course_section_name.slice(1)
                                        : ""}
                                    </div>
                                    <div
                                      style={{
                                        fontSize: "13px",
                                        color: "#002b73",
                                      }}
                                    >{`${lessons && lessons.length} ${
                                      lessons.length > 1 ? "lessons" : "lesson"
                                    }`}</div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "10px",
                                    }}
                                  >
                                    <img
                                      src={dropdown_navy_icon}
                                      alt="dropdown_navy_icon"
                                      width={15}
                                      height={10}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <div style={{ color: "#002b73" }}>
                                    {course_section_name
                                      ? course_section_name
                                          .charAt(0)
                                          .toUpperCase() +
                                        course_section_name.slice(1)
                                      : ""}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "13px",
                                        color: "#002b73",
                                      }}
                                    >{`${lessons && lessons.length} ${
                                      lessons.length > 1 ? "lessons" : "lesson"
                                    }`}</div>
                                    <img
                                      src={dropdown_navy_icon}
                                      alt="dropdown_navy_icon"
                                      width={15}
                                      height={10}
                                    />
                                  </div>
                                </>
                              )}
                            </CollapsibleTrigger>
                          }
                          triggerDisabled={isDisableOpenAccordion}
                        >
                          <CollapsibleContent>
                            <div
                              style={{
                                display: "flex",
                                gap: smallScreen ? "30px" : "100px",
                                marginBottom: "10px",
                                flexWrap: "wrap",
                              }}
                            >
                              {lessons &&
                              Array.isArray(lessons) &&
                              lessons.length > 0
                                ? lessons.map((eachLesson, idx) => {
                                    return (
                                      <CourseRecapDetailCoursePage
                                        eachLesson={eachLesson}
                                        key={idx}
                                        isStudent={true}
                                        isCourseDetailRecap={true}
                                        course_id={course_id}
                                        course_section_id={section["id"]}
                                        handleOnPauseVideo={handleOnPauseVideo}
                                        handleOnVideoEnded={handleOnVideoEnded}
                                        smallScreen={smallScreen}
                                      />
                                    );
                                  })
                                : null}
                            </div>
                          </CollapsibleContent>
                        </Collapsible>
                      );
                    })
                  : null}
              </>
            ) : null}
          </InnerContainer>
        </OuterContainer>
      )}
    </div>
  );
};

export default CourseDetail;

const OuterContainer = styled.div`
  text-align: left;
  @media ${device.laptopM} {
    padding-left: 350px;
  }
  @media ${device.laptopL} {
    padding-left: 350px;
  }
`;

const InnerContainer = styled.div`
  padding: 80px 2em 90px 2em;
  @media ${device.tablet} {
    padding: 130px 3vw 90px 3vw;
  }
  @media ${device.laptop} {
    padding: 130px 3vw 90px 3vw;
  }
  @media ${device.laptopM} {
    padding: 130px 3vw 30px 3vw;
  }
  @media ${device.laptopL} {
    padding: 130px 3vw 30px 3vw;
  }
`;

const PageTitle = styled.h1`
  font-size: 36px;
  color: #2c65f7;
  font-weight: bold;
`;

const Label = styled.h5`
  font-size: 24px;
  color: #2c65f7;
  font-weight: bold;
  @media ${device.tablet} {
    font-size: 16px;
  }
  @media ${device.laptop} {
    font-size: 16px;
  }
  @media ${device.laptopM} {
    font-size: 16px;
  }
  @media ${device.laptopL} {
    font-size: 16px;
  }
`;

const Text = styled.p`
  font-size: 20px;
  @media ${device.tablet} {
    font-size: 18px;
  }
  @media ${device.laptop} {
    font-size: 18px;
  }
  @media ${device.laptopM} {
    font-size: 18px;
  }
  @media ${device.laptopL} {
    font-size: 18px;
  }
  color: black;
`;

const TextTitle = styled.div`
  font-size: 25px;
  color: black;
  font-weight: bold;
`;

const BackNavigation = styled.p`
  padding: 0;
  font-size: 16px;
  color: #000000;
  cursor: pointer;
`;

const CollapsibleTrigger = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f3f3f3;
  font-size: 20px;
  padding: 12px 30px;
  font-weight: bold;
  color: #007c7c;
  margin: 5px -30px;
  user-select: none;
  :hover {
    background: #f3f3f3;
    opacity: 0.8;
  }
`;

const CollapsibleContent = styled.div`
  padding: 10px 0;
`;

const MentorOptions = styled.div`
  display: flex;
  flex-wrap: nowrap;
  min-width: 900px;
  font-weight: 500;
  color: #002b73;
`;

const MentorOptionSelect = styled.div`
  margin-top: 5vh;
  margin-bottom: 1vh;
  width: 33.3%;
  color: ${({ className, match }) =>
    className === match ? "#002b73" : "#AAAAAA"};
  font-weight: ${({ className, match }) =>
    className === match ? "bold" : "500"};
  /* flex-grow: 1; // Important */
`;

const MentorOptionLine = styled.hr`
  opacity: ${({ className, match }) => (className === match ? 1 : 0)};
  padding: 2px;
  margin-top: 0.5em;
`;

const MentorInvisibleLine = styled.hr`
  opacity: 0.2;
  margin-top: -29px;
  padding: 2px;
  min-width: 900px;
`;

const MentorOptionText = styled.p`
  text-align: center;
  padding: 0 1em 0;
  margin-bottom: 0;
  cursor: pointer;
  &:hover {
    color: #002b73;
    font-weight: ${({ className, match }) =>
      className === match ? "bold" : "500"};
  }

  &:hover + ${MentorOptionLine} {
    opacity: 1;
    color: #002b73;
    transition: 0.2s;
  }
`;

import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { DayPicker } from "react-day-picker";
import { format, parse } from "date-fns";

export default function DateTimeInput({
  data,
  placeholder,
  icon,
  value,
  selectedDate,
  selectedTime = "",
  handleSelectDate,
  handleSelectTime,
  inputValueDate,
  smallScreen,
}) {
  const ref = useRef(null);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [disabledDays, setDisabledDays] = useState([]);

  function handleDayClick(day, modifiers) {
    if (modifiers.disabled) {
      return;
    }
    handleSelectDate(day);
    setOpenDropdown(false);
  }

  function handleOpenDropdown() {
    setOpenDropdown(!openDropdown);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpenDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  function getDaysInYear(year) {
    let date = new Date(2022, 0, 1);
    let days = [];
    while (date.getFullYear() === 2022) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  }

  // Will be change into database
  const initialTimeList = [
    {
      hour: "09:00",
      status: "open",
    },
    {
      hour: "10:00",
      status: "open",
    },
    {
      hour: "11:00",
      status: "open",
    },
    {
      hour: "12:00",
      status: "open",
    },
    {
      hour: "13:00",
      status: "open",
    },
    {
      hour: "14:00",
      status: "open",
    },
    {
      hour: "15:00",
      status: "open",
    },
    {
      hour: "16:00",
      status: "open",
    },
  ];

  const [availableSchedule, setAvailableSchedule] = useState([]);

  const [selectedTimeList, setSelectedTimeList] = useState(initialTimeList);

  function dateConverter(date) {
    const zeroPad = (num, places) => String(num).padStart(places, "0");
    const selectedDate = new Date(date);
    let month = selectedDate.getMonth();
    switch (month) {
      case 0:
        month = "Jan";
        break;
      case 1:
        month = "Feb";
        break;
      case 2:
        month = "Mar";
        break;
      case 3:
        month = "Apr";
        break;
      case 4:
        month = "May";
        break;
      case 5:
        month = "Jun";
        break;
      case 6:
        month = "Jul";
        break;
      case 7:
        month = "Aug";
        break;
      case 8:
        month = "Sep";
        break;
      case 9:
        month = "Oct";
        break;
      case 10:
        month = "Nov";
        break;
      case 11:
        month = "Dec";
        break;
      default:
        return;
    }
    let newDate = zeroPad(selectedDate.getDate(), 2);
    let year = selectedDate.getFullYear();

    return `${month} ${newDate}, ${year}`;
  }

  // Convert date time to 'hh:mm'
  function timeConverter(date) {
    const zeroPad = (num, places) => String(num).padStart(places, "0");
    const selectedDate = new Date(date);
    const selectedTime = selectedDate.getHours();
    selectedDate.setHours(selectedTime, 0);

    return (zeroPad(selectedDate.getHours(), 2) + ":" + "00").toLocaleString();
  }

  // Convert status boolean to string
  function statusConverter(status) {
    if (status) return "filled";
    return "open";
  }

  // Set available schedule from database
  useEffect(() => {
    setAvailableSchedule(
      data.map((value) => {
        return {
          date: dateConverter(value.date),
          time: value.timeslots.map((value) => {
            return {
              id: value.id,
              hour: timeConverter(value.start_time),
              status: statusConverter(value.is_filled),
            };
          }),
        };
      })
    );
  }, [data]);

  useEffect(() => {
    const thisYear = new Date().getFullYear();

    const allDate = getDaysInYear(thisYear);
    const availableDates = availableSchedule
      .map((item) => new Date(item.date))
      .filter((value) => value >= new Date());
    const filterDates = allDate.filter(
      (item) =>
        !availableDates
          .map((date) => format(date, "dd/MM/yy"))
          .includes(format(item, "dd/MM/yy"))
    );

    const selected =
      selectedDate &&
      availableSchedule.filter(
        (schedule) => schedule.date === format(selectedDate, "LLL dd, yyyy")
      );

    // di comment sementara untuk demo

    // check if selected dates have schedule and fetch the hour time
    if (selected && selected.length > 0) {
      setSelectedTimeList(
        selected[0].time.sort((a, b) => {
          // sort hour from lowest to highest
          let currentHour = a.hour.split(":")[0];
          let nextHour = b.hour.split(":")[0];
          if (currentHour < nextHour) return -1;
          if (currentHour > nextHour) return 1;
          return 0;
        })
      );
    }
    setDisabledDays(filterDates);
    // setDisabledDays([...disabledDays, {
    //    daysOfWeek: [0, 6],
    // }, {
    //    before: new Date(),
    // }, ...filterDates])

    // if (selected && selected.length > 0) {
    //    setSelectedTimeList(initialTimeList)
    // }
    // setDisabledDays([...disabledDays, {
    //    dayOfWeek: [0, 6],
    // }, {
    //    before: new Date(),
    // }])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, data]);

  function handleTimeButtonClick(item) {
    if (item.status === "open") {
      handleSelectTime(item.hour, item.id);
      setOpenDropdown(false);
    }
  }

  return (
    <DateTimeDropdown ref={ref}>
      <DateTimeButton
        id="dropbtn"
        onClick={value === "date" ? null : handleOpenDropdown}
        onBlur={value === "date" ? null : handleOpenDropdown}
      >
        <div>
          {value === "date" ? (
            <input
              // size={12}
              type="text"
              placeholder={placeholder}
              style={{
                border: "none",
                width: "27.5vw",
                fontWeight: "bold",
              }}
              value={inputValueDate}
              onChange={({ target: { value } }) =>
                handleSelectDate(null, value)
              }
            />
          ) : selectedTime === "" ? (
            <span style={{ color: "#AAAAAA" }}>{placeholder}</span>
          ) : (
            <span style={{ fontWeight: "bold" }}>{selectedTime}</span>
          )}
        </div>
        <div>
          <img
            src={icon}
            alt="dateicon"
            width={20}
            height={20}
            onClick={value === "date" ? handleOpenDropdown : null}
            onBlur={value === "date" ? handleOpenDropdown : null}
          />
        </div>
      </DateTimeButton>
      <DateTimeContent
        id="dropdown-content"
        style={{
          display: openDropdown ? "flex" : "none",
        }}
      >
        {value === "date" ? (
          <DayPicker
            onDayClick={handleDayClick}
            selected={selectedDate}
            disabled={disabledDays}
          />
        ) : (
          <TimePicker smallScreen={smallScreen}>
            {selectedDate &&
              selectedTimeList.map((item, index) => {
                return (
                  <TimeButton
                    filled={item.status === "filled"}
                    key={index}
                    style={{
                      opacity: item.status === "filled" ? 0.3 : undefined,
                      cursor: item.status === "filled" ? "default" : undefined,
                    }}
                    onClick={() => handleTimeButtonClick(item)}
                  >
                    {item.hour}
                  </TimeButton>
                );
              })}
          </TimePicker>
        )}
      </DateTimeContent>
    </DateTimeDropdown>
  );
}

const DateTimeDropdown = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
  :hover {
    #dropdown-content {
      display: block;
    }
  }
`;

const DateTimeButton = styled.div`
  width: 100%;
  border: 2px solid #00bb99;
  padding: 10px 15px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  user-select: none;
`;

const DateTimeContent = styled.div`
  width: 100%;
  display: none;
  position: absolute;
  justify-content: center;
  background-color: #f1f1f1;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
`;

const TimePicker = styled.div`
  display: flex;
  justify-content: ${({ smallScreen }) => (smallScreen ? "" : "center")};
  gap: ${({ smallScreen }) => (smallScreen ? "2vw" : "0.5vw")};
  width: 100%;
  padding: ${({ smallScreen }) => (smallScreen ? "1rem" : "2rem")};
  flex-wrap: wrap;
`;

const TimeButton = styled.button`
  background: #00bb99;
  color: white;
  border: 0;
  padding: 0.5vh 0.5vw;
  border-radius: 50px;
  ${({ filled }) =>
    !filled &&
    `
      :hover {
         transform: translateY(-2px);
      }
   `}
`;

import React from "react";

import { useNavigate } from "react-router-dom";

// Styles
import "../styles/coachingBook.css";

// Images & Icons
import arrow_left_icon_dark from "../assets/icons/arrow_left_icon_dark.png";

// lib
import font_size from "../lib/styling/font_size";

const CoachingBookRequirements = () => {
  const navigate = useNavigate();
  return (
    <div className="coachingBookMainContainer">
      <div className="coachingBookFlexContainer">
        <div className="coachingBookCenteredBox">
          <div style={{ height: "100%", textAlign: "left" }}>
            <div
              onClick={() => navigate(-1)}
              style={{
                color: "black",
                fontSize: font_size.title3,
                userSelect: "none",
                display: "flex",
                gap: "0.25vw",
              }}
            >
              <img
                src={arrow_left_icon_dark}
                className="coachingBookArrowLeft"
              />
              <div
                style={{
                  color: "black",
                  fontSize: font_size.title3,
                  userSelect: "none",
                }}
              >
                Back
              </div>
            </div>
            <div
              style={{
                fontSize: font_size.title1,
                color: "#00BB99",
                fontWeight: "bold",
                marginBottom: "20px",
                paddingTop: "2.5vh",
              }}
            >
              Entrepreneurship session requirements
            </div>
            <div style={{ paddingTop: "1vh" }}>
              <div
                style={{
                  paddingBottom: "1vh",
                  fontWeight: "bold",
                  fontSize: font_size.title2,
                }}
              >
                STEP 1
              </div>
              <div>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </div>
            </div>
            <div style={{ paddingTop: "4vh" }}>
              <div
                style={{
                  paddingBottom: "1vh",
                  fontWeight: "bold",
                  fontSize: font_size.title2,
                }}
              >
                STEP 2
              </div>
              <div>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachingBookRequirements;

import Axios from "../../helpers/axios";

export const getAllUsers = () => {
  return Axios.get("/users");
};

export const getUserDetail = (user_id) => {
  return Axios.get(`/users/${user_id}`);
};

export const updateUserRole = (user_id, body) => {
  return Axios.patch(`/users/update/role/${user_id}`, body);
};

export const changeUserVerifiedStatus = (user_id, body) => {
  return Axios.patch(`/users/verified-status/${user_id}`, body);
};

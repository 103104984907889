import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import styled from "styled-components";

// Components
import HeaderComponent from "../components/HeaderComponent";
import SidebarComponent from "../components/SidebarComponent";
import UserForm from "../components/profile/UserForm";
import SpinnerComponent from "../components/SpinnerComponent";
import LoadingModalMobile from "../components/LoadingModalMobile";

// Helpers
import { device } from "../helpers/device";

// Apis
import {
  getAllCountries,
  getAllDegrees,
  getAllIndustries,
  getAllOccupations,
} from "../apis/general";

// react-responsive
import { useMediaQuery } from "react-responsive";

const Profile = () => {
  const userDetail = useSelector((state) => state.userDetail);

  const smallScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const mediumScreen = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1200px)",
  });

  const [isLoading, setLoading] = useState(false);
  const [isNewUser, setIsNewUser] = useState(null);
  const [industries, setIndustries] = useState(null);
  const [countries, setCountries] = useState(null);
  const [occupations, setOccupations] = useState(null);
  const [degrees, setDegrees] = useState(null);

  const handleFetchIndustries = async () => {
    setLoading(true);
    const { data: industries } = await getAllIndustries();
    if (industries) {
      setIndustries(industries);
    }
    setLoading(false);
  };

  const handleFetchCountries = async () => {
    setLoading(true);
    const { data: countries } = await getAllCountries();
    if (countries) {
      setCountries(countries);
    }
    setLoading(false);
  };

  const handleFetchOccupations = async () => {
    setLoading(true);
    const { data: occupations } = await getAllOccupations();
    if (occupations) {
      setOccupations(occupations);
    }
    setLoading(false);
  };

  const handleFetchDegrees = async () => {
    setLoading(true);
    const { data: degrees } = await getAllDegrees();
    if (degrees) {
      setDegrees(degrees);
    }
    setLoading(false);
  };

  const handleChangeIsNewUserState = (propIsNewUser) => {
    setIsNewUser(propIsNewUser);
  };

  useEffect(() => {
    handleFetchIndustries();
    handleFetchCountries();
    handleFetchOccupations();
    handleFetchDegrees();
  }, []);

  useEffect(() => {
    if (userDetail) {
      handleChangeIsNewUserState(userDetail.isNewUser);
    }
  }, [userDetail]);

  return (
    <div>
      <SidebarComponent />
      <div style={{ paddingLeft: smallScreen || mediumScreen ? "" : "350px" }}>
        <HeaderComponent />
        <SpinnerComponent
          isShown={isLoading && !smallScreen && !mediumScreen}
          right="20px"
          top="20px"
        />
        {isLoading && (smallScreen || mediumScreen) ? (
          <LoadingModalMobile />
        ) : (
          <MyProfileInnerContainer>
            {userDetail &&
              industries &&
              countries &&
              occupations &&
              degrees && (
                <UserForm
                  user={userDetail}
                  isNewUser={isNewUser}
                  setIsNewUser={setIsNewUser}
                  industries={industries}
                  countries={countries}
                  occupations={occupations}
                  degrees={degrees}
                />
              )}
          </MyProfileInnerContainer>
        )}
      </div>
    </div>
  );
};

export default Profile;

const MyProfileInnerContainer = styled.div`
  padding: 80px 8vw 90px 8vw;
  text-align: left;
  @media ${device.tablet} {
    padding: 130px 3vw 90px 3vw;
  }
  @media ${device.laptop} {
    padding: 130px 3vw 90px 3vw;
  }
  @media ${device.laptopM} {
    padding: 130px 3vw 30px 3vw;
  }
  @media ${device.laptopL} {
    padding: 130px 3vw 30px 3vw;
  }
`;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

// images & icons
import NoInternetConnection from "../assets/icons/NoInternetIcon.json";

// React Lottie
import Lottie from "lottie-react";

const CheckInternetConnection = () => {
  const smallScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const mediumScreen = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1200px)",
  });

  const userDetail = useSelector((state) => state.userDetail);

  const [isOnline, setIsOnline] = useState(() => {
    if (navigator.onLine) {
      return true;
    } else {
      return false;
    }
  });

  useEffect(() => {
    window.ononline = (e) => {
      setIsOnline(true);
    };
    window.onoffline = (e) => {
      setIsOnline(false);
    };
  }, [isOnline]);

  if (isOnline) {
    return null;
  } else {
    return (
      <div className="fullscreenContainer" tabIndex={0}>
        <div
          className="noInternetContentContainer"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Lottie
            animationData={NoInternetConnection}
            loop={true}
            style={{
              height: smallScreen ? 175 : mediumScreen ? 225 : 275,
            }}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <h1
              style={{
                color: "black",
                marginBottom: "1em",
                fontSize: smallScreen || mediumScreen ? "40px" : "",
              }}
            >
              Whoops!
            </h1>
            <h3
              style={{
                color: "black",
                width: smallScreen ? "70vw" : mediumScreen ? "50vw" : "25vw",
                marginBottom: "1em",
              }}
            >
              No Internet connection found. Check your connection or try again.
            </h3>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div
              style={{
                background:
                  userDetail && userDetail.is_admin === true
                    ? "#d7ffff"
                    : "#2c65f7",
                borderRadius: "10px",
                cursor: "pointer",
              }}
              onClick={() => window.location.reload()}
            >
              <div
                style={{
                  fontSize: smallScreen || mediumScreen ? "20px" : "0.95vw",
                  color: "#FFFFFF",
                  margin:
                    smallScreen || mediumScreen
                      ? "1.25vh 2vw 1.25vh 2vw"
                      : "1.25vh 1vw 1.25vh 1vw",
                }}
              >
                Try again
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default CheckInternetConnection;

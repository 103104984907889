// React
import React, { useEffect, useState } from "react";
import styled from "styled-components";
// React bootstrap
import Dropdown from "react-bootstrap/Dropdown";

import circle_check_icon from "../../assets/icons/circle_check_icon.svg";
import dropdown_icon from "../../assets/icons/dropdown_icon.svg";

export default function CustomDropdown({
  value,
  placeholder,
  onSelect,
  options,
  filteredOptions,
  titleKey = "name",
  action,
  isSelected = false,
  selectedKey = "id",
  is_error = false,
  inputBackgroundColor,
  inputFilledBackgroundColor,
  fromprofile = "",
  selectedBackgroundColor = "rgba(0, 187, 153, 0.1)",
  disabled = false,
  showSelectedIcon = true,
  padding,
  imageFile,
  itemWidth,
  containerWidth,
  height,
  inputWidth,
  user_detail,
  isError = false,
  label,
  propStyle,
}) {
  const [selected, setSelected] = useState(value);
  useEffect(() => {
    if (value) {
      if (label && label === "assignInstructor") {
        setSelected(value[selectedKey]);
      } else {
        setSelected(value);
      }
      action(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  function handleChange(option) {
    if (label && label === "assignInstructor") {
      setSelected(option[selectedKey]);
    } else {
      setSelected(option);
    }
    action(option);
  }

  function renderDropdownMenu() {
    let component =
      options.length > 0 ? (
        options.map((option, i) => {
          return (
            <div key={i}>
              {filteredOptions?.map((value) => value.id).includes(option.id) ? (
                <Dropdown.Item key={i} disabled>
                  <DropdownItemContainer>
                    <DropdownEachItem>
                      {option.name === "KTP" ? (
                        <>
                          {<span>{option[titleKey]}</span>}{" "}
                          <DropdownSpan>(for indonesians only)</DropdownSpan>
                        </>
                      ) : (
                        option[titleKey] || option
                      )}
                    </DropdownEachItem>
                    <DropdownEachItem>
                      {imageFile && (
                        <img
                          src={imageFile}
                          alt="dropdown-item-icon"
                          width={15}
                          height={15}
                        />
                      )}
                    </DropdownEachItem>
                  </DropdownItemContainer>
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  key={i}
                  onClick={() =>
                    label && label === "assignInstructor"
                      ? handleChange(option)
                      : handleChange(option[selectedKey])
                  }
                >
                  <DropdownItemContainer>
                    <DropdownEachItem>
                      {option.name === "KTP" ? (
                        <>
                          {<span>{option[titleKey]}</span>}{" "}
                          <DropdownSpan>(for indonesians only)</DropdownSpan>
                        </>
                      ) : (
                        option[titleKey] || option
                      )}
                    </DropdownEachItem>
                    <DropdownEachItem>
                      {imageFile && (
                        <img
                          src={imageFile}
                          alt="dropdown-item-icon"
                          width={15}
                          height={15}
                        />
                      )}
                    </DropdownEachItem>
                  </DropdownItemContainer>
                </Dropdown.Item>
              )}
            </div>
          );
        })
      ) : (
        <Dropdown.Item disabled>
          <DropdownItemContainer>
            <DropdownEachItem>Data not found</DropdownEachItem>
            <DropdownEachItem>
              {imageFile && (
                <img
                  src={imageFile}
                  alt="dropdown-item-icon"
                  width={15}
                  height={15}
                />
              )}
            </DropdownEachItem>
          </DropdownItemContainer>
        </Dropdown.Item>
      );
    return component;
  }

  function renderDropdownText() {
    let text;
    options?.forEach((item) => {
      if (item[selectedKey] === selected) {
        text = item[titleKey];
      }
    });
    if (text) {
      return text;
    } else {
      return placeholder;
    }
  }

  return (
    <DropdownContainer
      fromprofile={fromprofile}
      disabled={disabled}
      padding={padding}
      minWidth={containerWidth}
    >
      <Dropdown onBlur={onSelect}>
        <Dropdown.Toggle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: isSelected
              ? selectedBackgroundColor
              : inputBackgroundColor
              ? inputBackgroundColor
              : "transparent",
            color: selected ? "black" : "#AAAAAA",
            height: height,
            width: inputWidth ? inputWidth : "100%",
            minWidth: "fit-content",
            border:
              isError || is_error
                ? "2px solid #FF9494"
                : `2px solid ${
                    user_detail && user_detail.is_admin === false
                      ? "#2c65f7"
                      : "#00BB99"
                  }`,
            padding: "10px 15px",
            borderRadius: "10px",
            fontWeight: !fromprofile && value ? "bold" : undefined,
            ...propStyle,
          }}
        >
          {selected ? renderDropdownText() : placeholder}
          {fromprofile ? (
            isSelected ? null : (
              <img
                src={dropdown_icon}
                alt="dropdown-icon"
                width={15}
                height={10}
              />
            )
          ) : (
            <img
              src={isSelected ? circle_check_icon : dropdown_icon}
              alt="dropdown-icon"
              width={isSelected ? 24 : 15}
              height={isSelected ? 24 : 10}
            />
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu
          style={{
            width: itemWidth ? itemWidth : "100%",
            maxHeight: "500px",
            backgroundColor: "#FAFAFA",
            border: "1px solid #00BB9",
            overflow: "hidden",
            overflowY: "auto",
          }}
        >
          {renderDropdownMenu()}
        </Dropdown.Menu>
      </Dropdown>
    </DropdownContainer>
  );
}

const DropdownContainer = styled.div`
  min-width: ${({ minWidth }) => (minWidth ? minWidth : "none")};
  padding-left: ${({ padding }) => (padding ? padding : "0")};
  padding-right: ${({ padding }) => (padding ? padding : "0")};
  opacity: ${({ disabled, fromprofile }) =>
    disabled && !fromprofile ? 0.35 : 1};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  background: ${({ disabled }) =>
    disabled ? "rgba(0, 187, 153, 0.1)" : "none"};
  .dropdown-toggle::after {
    display: none;
  }
  &:valid {
    background: rgba(0, 221, 221, 0.15);
  }
`;

const DropdownItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DropdownEachItem = styled.div``;

const DropdownSpan = styled.span`
  color: #aaaaaa;
`;

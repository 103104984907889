import React, { useEffect, useRef, useState } from "react";

// Bootstrap
import Spinner from "react-bootstrap/Spinner";

// helpers
import getTimeFromSeconds from "../helpers/get-time-from-seconds";

import ApiVideoPlayer from "@api.video/react-player";

const VideoPlayer = (props) => {
  const {
    videoSrc,
    lesson,
    handleOnPauseVideo,
    handleOnVideoEnded,
    course_id,
    course_section_id,
    last_video_watched_in_seconds,
    isStudent,
    duration_in_seconds,
    is_finish_watching,
    smallScreen,
  } = props;

  const videoRef = useRef(null);
  const playerRef = useRef(null);
  // const [videoFile, setVideoFile] = useState(null);
  // const [currentTime, setCurrentTime] = useState(0);
  const currentTimeRef = useRef(null);

  let durationValue = getTimeFromSeconds(duration_in_seconds);

  // useEffect(() => {
  //   const iframe = iframeRef.current;

  //   const handlePlayEvent = () => {
  //     console.log("Video is playing");
  //   };

  //   const handlePauseEvent = () => {
  //     console.log("Video is paused");
  //   };

  //   const handleEndedEvent = () => {
  //     console.log("Video has finished");
  //   };

  //   const handleMessage = (event) => {
  //     const data = event.data;
  //     if (data === "play") {
  //       handlePlayEvent();
  //     } else if (data === "pause") {
  //       handlePauseEvent();
  //     } else if (data === "ended") {
  //       handleEndedEvent();
  //     }
  //   };

  //   console.log(iframe, "iframe <<<");

  //   if (iframe) {
  //     window.addEventListener("message", handleMessage);

  //     return () => {
  //       window.removeEventListener("message", handleMessage);
  //     };
  //   }
  // }, [iframeRef]);

  // const handleDownloadVideo = async (videoUrl) => {
  //   try {
  //     const response = await fetch(videoUrl);
  //     const blob = await response.blob();
  //     const url = window.URL.createObjectURL(blob);
  //     setVideoFile(url);
  //   } catch (error) {
  //     console.error("Error downloading the video:", error);
  //   }
  // };

  const handleOnClickBtnContinueWatch = (last_video_watched_in_seconds) => {
    if (videoRef.current && last_video_watched_in_seconds) {
      videoRef.current.play();
      videoRef.current.setCurrentTime(last_video_watched_in_seconds);
      videoRef.current.requestFullscreen();
      videoRef.current.pause();
    }
    // if (videoRef.current) {
    //   if (videoRef.current.requestFullscreen) {
    //     videoRef.current.requestFullscreen();
    //   } else if (videoRef.current.mozRequestFullScreen) {
    //     /* Firefox */
    //     videoRef.current.mozRequestFullScreen();
    //   } else if (videoRef.current.webkitRequestFullscreen) {
    //     /* Chrome, Safari & Opera */
    //     videoRef.current.webkitRequestFullscreen();
    //   } else if (videoRef.current.msRequestFullscreen) {
    //     /* IE/Edge */
    //     videoRef.current.msRequestFullscreen();
    //   }
    //   videoRef.current.currentTime = last_video_watched_in_seconds;
    //   videoRef.current.play();
    // }
  };

  const handlePauseVideo = async () => {
    let { currentTime } = currentTimeRef.current;
    if (handleOnPauseVideo && !is_finish_watching) {
      await handleOnPauseVideo(
        course_id,
        course_section_id,
        lesson["id"],
        currentTime
      );
    }

    // let time = videoRef.current?.setCurrentTime(3);
    // console.log(time, "time <<<");
    // // ini untuk skip video pas pertama kali fetch kalau ada second last watched nya
    // let pauseObj = videoRef.current.currentTime();

    // const currentTime = event.target.currentTime;
    // console.log("Video paused at:", currentTime);
  };

  const handleOnTimeUpdate = (currentTime) => {
    currentTimeRef.current = { currentTime };
  };

  // useEffect(() => {
  //   if (videoSrc) {
  //     handleDownloadVideo(videoSrc);
  //   }
  // }, [videoSrc]);

  useEffect(() => {
    if (last_video_watched_in_seconds && videoRef && videoRef.current) {
      // videoRef.current.play();
      videoRef.current.setCurrentTime(last_video_watched_in_seconds);
      // videoRef.current.pause();
    }
  }, [videoRef, last_video_watched_in_seconds]);

  return (
    <div style={{ width: smallScreen ? "100%" : "" }}>
      {lesson && lesson["lesson_video_id"] ? (
        <ApiVideoPlayer
          ref={videoRef}
          video={{ id: lesson["lesson_video_id"] }}
          style={{
            width: smallScreen ? "100%" : "550px",
            height: smallScreen ? "300px" : "350px",
            objectFit: "cover",
            borderRadius: "15px",
          }}
          onPause={handlePauseVideo}
          onTimeUpdate={(currentTime) => handleOnTimeUpdate(currentTime)}
          onReady={(player) => {
            playerRef.current = player;
          }}
          onEnded={() =>
            handleOnVideoEnded
              ? handleOnVideoEnded(course_id, course_section_id, lesson["id"])
              : null
          }
        />
      ) : null}
      {/* {lesson && lesson["video_api_url"] ? (
        <iframe
          ref={iframeRef}
          src={lesson["video_api_url"]}
          style={{
            width: "550px",
            height: "350px",
            objectFit: "cover",
            borderRadius: "15px",
          }}
          // width="100%"
          // height="100%"
          // frameborder="0"
          scrolling="no"
          allowfullscreen="true"
        ></iframe>
      ) : (
        <video
          ref={videoRef}
          style={{
            width: "384px",
            height: "254px",
            objectFit: "cover",
            borderRadius: "15px",
          }}
          controls
          onPause={() =>
            handleOnPauseVideo
              ? handleOnPauseVideo(
                  course_id,
                  course_section_id,
                  lesson["id"],
                  videoRef.current.currentTime
                )
              : null
          }
        >
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )} */}

      {/* {videoFile ? (
        <video
          ref={videoRef}
          style={{
            width: "384px",
            height: "254px",
            objectFit: "cover",
            borderRadius: "15px",
          }}
          controls
          onPause={() =>
            handleOnPauseVideo
              ? handleOnPauseVideo(
                  course_id,
                  course_section_id,
                  lesson["id"],
                  videoRef.current.currentTime
                )
              : null
          }
        >
          <source src={videoFile} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <div
          style={{
            width: "384px",
            height: "254px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "15px",
            backgroundColor: "#aaaaaa",
          }}
        >
          <Spinner animation="border" role="status" />
          <div style={{ marginTop: "1em" }}>Loading video, please wait..</div>
        </div>
      )} */}
      {isStudent ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            {lesson && lesson["lesson_name"] ? (
              <div
                className="showLessonImageContainerLessonName"
                style={{ color: isStudent ? "#002b73" : "#007c7c" }}
              >
                {lesson["lesson_name"].charAt(0).toUpperCase() +
                  lesson["lesson_name"].slice(1)}
              </div>
            ) : null}
            {lesson &&
            lesson["content_type"] &&
            lesson["content_type"] === "video" &&
            duration_in_seconds &&
            durationValue ? (
              <div
                className="showLessonImageContainerLessonDuration"
                style={{ color: isStudent ? "#002b73" : "#007c7c" }}
              >
                {`${
                  durationValue.hours
                    ? `${
                        durationValue.hours > 1
                          ? `${durationValue.hours} hours`
                          : `${durationValue.hours} hour`
                      }`
                    : ``
                } ${
                  durationValue.minutes
                    ? `${
                        durationValue.minutes > 1
                          ? `${durationValue.minutes} mins`
                          : `${durationValue.minutes} min`
                      }`
                    : ``
                } ${
                  !durationValue.hours && durationValue.seconds
                    ? `${
                        durationValue.seconds > 1
                          ? `${durationValue.seconds} secs`
                          : `${durationValue.seconds} sec`
                      }`
                    : ``
                }`}
              </div>
            ) : null}
          </div>
          {last_video_watched_in_seconds &&
          videoRef &&
          videoRef.current &&
          !is_finish_watching ? (
            <div
              className="btnContinueWatchVideoContainer"
              onClick={() =>
                handleOnClickBtnContinueWatch(last_video_watched_in_seconds)
              }
            >
              <div style={{ margin: "0.5em 1em" }}>Continue watch</div>
            </div>
          ) : last_video_watched_in_seconds &&
            videoRef &&
            videoRef.current &&
            is_finish_watching ? (
            <div className="btnContinueWatchVideoContainerDisabled">
              <div style={{ margin: "0.5em 1em" }}>Watched</div>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default VideoPlayer;

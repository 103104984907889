import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";

// react bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// HTML react parser
import parse from "html-react-parser";

// Images and Icons
import arrow_left_icon_dark from "../assets/icons/arrow_left_icon_dark.png";
import dropdown_green_icon from "../assets/icons/dropdown_green_icon.svg";
import empty_session_2_icon from "../assets/icons/empty_session_2_icon.svg";

// react-collapsible
import Collapsible from "react-collapsible";

// Components
import HeaderComponent from "../components/HeaderComponent";
import SidebarInteralComponent from "../components/SidebarInternalComponent";
import SpinnerComponent from "../components/SpinnerComponent";
import CourseRecapDetailCoursePage from "../components/CourseRecapDetailCoursePage";
import Pagination from "../components/layout/Pagination";
import TableInternalComponent from "../components/TableInternalComponent";

// Apis
import { getDetailCourse } from "../apis/courses";
import { getAllStudentListWhoAddedCourse } from "../apis/internalApis/courses";

// Helpers
import getTimeFromSeconds from "../helpers/get-time-from-seconds";

// api video player library
import ApiVideoPlayer from "@api.video/react-player";

// import { getVideoByVideoId } from "../api/apiVideo";

const InternalCourseDetail = () => {
  const navigate = useNavigate();
  const { course_id } = useParams();

  const [isLoading, setLoading] = useState(false);
  const [courseSections, setCourseSections] = useState();
  const [courseDetails, setCourseDetails] = useState(null);
  const [infoName, setInfoName] = useState(
    "what_will_you_learn_in_this_course"
  );

  const [courseStudentList, setCourseStudentList] = useState([]);
  const [perPage, setPerPage] = useState(15);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  const [data, setData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);

  let initialDataState = courseStudentList
    .sort((a, b) => {
      return new Date(b.updatedAt) - new Date(a.updatedAt);
    })
    .map(({ last_activity, student: { id, email, user_details } }) => {
      let username = `${user_details.first_name} ${user_details.last_name}`;
      let date = null;
      if (last_activity) {
        date = new Date(last_activity).toLocaleString();
      }
      return {
        id,
        username,
        email_address: email,
        last_activity: date,
        // registered_date: date,
        // is_verified: is_validated,
      };
    });

  // Handle back button
  function backToCourseList() {
    return navigate(-1);
  }

  // Options renderer
  function renderOptions() {
    if (infoName === "what_will_you_learn_in_this_course") {
      return <div>{parse(courseDetails?.what_you_will_learn)}</div>;
    } else if (infoName === "target_audience") {
      return <div>{parse(courseDetails?.target_audience)}</div>;
    } else if (infoName === "additional_description") {
      return <div>{parse(courseDetails?.additional_description)}</div>;
    } else {
      return (
        <div>
          <p>Data not found, please try another options...</p>
        </div>
      );
    }
  }

  const handleFetchCourseDetail = async (course_id) => {
    const result = await getDetailCourse(course_id);
    if (result && result.data) {
      const { course_video_id } = result.data;
      let finalCourseDetail = result.data;
      // if (course_video_id) {
      //   const getVideo = await getVideoByVideoId(course_video_id);
      //   if (
      //     getVideo &&
      //     getVideo.data &&
      //     getVideo.data.assets &&
      //     getVideo.data.assets.player
      //   ) {
      //     finalCourseDetail["video_api_url"] = getVideo.data.assets.player;
      //   }
      // }
      let finalCourseSections = result.data.course_sections;
      // for (let fcs = 0; fcs < finalCourseSections.length; fcs++) {
      //   const { lessons } = finalCourseSections[fcs];
      //   for (let l = 0; l < lessons.length; l++) {
      //     const { content_type, lesson_video_id } = lessons[l];
      //     if (content_type && content_type === "video" && lesson_video_id) {
      //       let getLessonVideo = await getVideoByVideoId(lesson_video_id);
      //       if (
      //         getLessonVideo &&
      //         getLessonVideo.data &&
      //         getLessonVideo.data.assets &&
      //         getLessonVideo.data.assets.player
      //       ) {
      //         finalCourseSections[fcs]["lessons"][l]["video_api_url"] =
      //           getLessonVideo.data.assets.player;
      //       }
      //     }
      //   }
      // }
      setCourseDetails(finalCourseDetail);
      setCourseSections(finalCourseSections);
    }
  };

  const getTotalDurationCourse = (course) => {
    const { course_sections } = course || {};
    let totalDurationInSeconds = 0;
    for (let cs = 0; cs < course_sections.length; cs++) {
      const { total_course_duration_in_seconds } = course_sections[cs];
      totalDurationInSeconds += total_course_duration_in_seconds;
    }
    totalDurationInSeconds = getTimeFromSeconds(totalDurationInSeconds);
    totalDurationInSeconds = `${
      totalDurationInSeconds.hours
        ? `${
            totalDurationInSeconds.hours > 1
              ? `${totalDurationInSeconds.hours} hours`
              : `${totalDurationInSeconds.hours} hour`
          }`
        : ``
    } ${
      totalDurationInSeconds.minutes
        ? `${
            totalDurationInSeconds.minutes > 1
              ? `${totalDurationInSeconds.minutes} mins`
              : `${totalDurationInSeconds.minutes} min`
          }`
        : ``
    } ${
      !totalDurationInSeconds.hours && totalDurationInSeconds.seconds
        ? `${
            totalDurationInSeconds.seconds > 1
              ? `${totalDurationInSeconds.seconds} secs`
              : `${totalDurationInSeconds.seconds} sec`
          }`
        : ``
    }`;
    return totalDurationInSeconds;
  };

  const handleFetchCourseStudentList = async (course_id) => {
    setLoading(true);
    const result = await getAllStudentListWhoAddedCourse(course_id);
    if (result && result.data) {
      const { data } = result;
      setCourseStudentList(data);
    }
    setLoading(false);
  };

  const handleFormatFirstFetchPaginatedData = (courseStudentList) => {
    let initialDataState = courseStudentList
      .sort((a, b) => {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      })
      .map(({ last_activity, student: { id, email, user_details } }) => {
        let username = `${user_details.first_name} ${user_details.last_name}`;
        let date = null;
        if (last_activity) {
          date = new Date(last_activity).toLocaleString();
        }
        return {
          id,
          username,
          email_address: email,
          last_activity: date,
          // registered_date: date,
          // is_verified: is_validated,
        };
      });
    setData(initialDataState);
    const endOffset = itemOffset + perPage;
    setPaginatedData(initialDataState.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / perPage));
  };

  function handlePageClick(e) {
    const newOffset = (e.selected * perPage) % data.length;
    setItemOffset(newOffset);
  }

  function handleOnClickRow(d, key, value) {
    navigate(`/internal/users/${d["id"]}`);
  }

  useEffect(() => {
    if (course_id) {
      handleFetchCourseDetail(course_id);
      handleFetchCourseStudentList(course_id);
    }
  }, [course_id]);

  useEffect(() => {
    if (
      courseStudentList &&
      Array.isArray(courseStudentList) &&
      courseStudentList.length > 0
    ) {
      handleFormatFirstFetchPaginatedData(courseStudentList);
    }
  }, [courseStudentList]);

  useEffect(() => {
    const endOffset = itemOffset + perPage;
    setPaginatedData(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / perPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, itemOffset, perPage]);

  return (
    <div>
      <HeaderComponent />
      <SidebarInteralComponent />
      <OuterContainer>
        <SpinnerComponent isShown={isLoading} right="20px" top="20px" />
        <InnerContainer>
          <Row>
            <BackNavigation onClick={backToCourseList}>
              <img
                src={arrow_left_icon_dark}
                alt="arrow_left_icon_dark"
                height={15}
                width={15}
                style={{
                  objectFit: "scale-down",
                  objectPosition: "left bottom",
                }}
              />
              {`    Back`}
            </BackNavigation>
          </Row>
          <Row style={{ marginBottom: 20 }}>
            <Col>
              <PageTitle>Course Details</PageTitle>
            </Col>
          </Row>
          {courseDetails ? (
            <>
              <Row>
                <Col
                  style={{
                    marginBottom: "2vh",
                    gap: "10vw",
                    marginLeft: "20vh",
                    marginRight: "20vh",
                    // width: "70%",
                  }}
                >
                  <div
                    style={{
                      // width: "384px",
                      // height: "254px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "4vh",
                    }}
                  >
                    <img
                      src={courseDetails?.course_image_url}
                      style={{
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        width: "900px",
                        height: "500px",
                        // width: "100%",
                        // height: "auto",
                        // maxWidth: "384px",
                        // maxHeight: "254px",
                        // width: "100%",
                        // height: "53.5vh",
                        objectFit: "cover",
                        borderRadius: "30px",
                      }}
                      alt="courseImage"
                    />
                  </div>
                  {courseDetails && courseDetails.course_video_id ? (
                    // <div
                    //   style={{
                    //     // width: "42.5%",
                    //     // width: "384px",
                    //     // height: "254px",
                    //     width: "875px",
                    //     height: "500px",
                    //     display: "flex",
                    //     justifyContent: "center",
                    //     alignItems: "center",
                    //     marginBottom: "2vh",
                    //     borderRadius: "50px",
                    //     paddingTop: "5px",
                    //     paddingBottom: "5px",
                    //   }}
                    // >
                    //   <ApiVideoPlayer
                    //     video={{ id: "vi5fv44Hol1jFrCovyktAJS9" }}
                    //     style={{
                    //       width: "875px",
                    //       height: "500px",
                    //       borderRadius: "50px",
                    //       paddingTop: "5px",
                    //       paddingBottom: "5px",
                    //     }}
                    //   />
                    //   <video
                    //         style={{
                    //           width: "100%",
                    //           // height: "53.5vh",
                    //           // width: "384px",
                    //           // height: "254px",
                    //           borderRadius: "30px",
                    //           paddingTop: "5px",
                    //           paddingBottom: "5px",
                    //         }}
                    //         controls={true}
                    //       >
                    //         <source
                    //           src={courseDetails?.course_video_highlight_url}
                    //           type="video/mp4"
                    //         />
                    //       </video>
                    // </div>
                    <ApiVideoPlayer
                      // ref={videoRef}
                      video={{ id: courseDetails.course_video_id }}
                      style={{
                        width: "900px",
                        height: "500px",
                        objectFit: "cover",
                        borderRadius: "15px",
                      }}
                      // options={{
                      //   autoplay: false,
                      //   controls: true,
                      // }}
                      // onReady={(player) => {
                      //   videoRef.current = player;
                      // }}
                      // onPause={handlePauseVideo}
                      // onTimeUpdate={(currentTime) =>
                      //   handleOnTimeUpdate(currentTime)
                      // }
                    />
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>Course type</Label>
                  <Text>{courseDetails?.course_type}</Text>
                </Col>
                <Col>
                  <Label>Course length</Label>
                  <Text>{courseDetails?.course_length}</Text>
                </Col>
                <Col>
                  <Label>Course language</Label>
                  <Text>{courseDetails?.course_language}</Text>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>Course level</Label>
                  <Text>{courseDetails?.course_level}</Text>
                </Col>
                <Col>
                  <Label>Course category</Label>
                  <Text>{courseDetails?.course_category}</Text>
                </Col>
                <Col>
                  <Label>Course sub-category</Label>
                  <Text>{courseDetails?.course_sub_category}</Text>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>Assigned instructor</Label>
                  <Text>{`${courseDetails?.assigned_instructor?.first_name} ${courseDetails?.assigned_instructor?.last_name}`}</Text>
                </Col>
              </Row>
              <MentorOptions>
                <MentorOptionSelect
                  className={infoName}
                  match="what_will_you_learn_in_this_course"
                  onClick={() =>
                    setInfoName("what_will_you_learn_in_this_course")
                  }
                >
                  <MentorOptionText>
                    What will you learn in this course ?
                  </MentorOptionText>
                  <MentorOptionLine
                    className={infoName}
                    match="what_will_you_learn_in_this_course"
                  />
                </MentorOptionSelect>
                <MentorOptionSelect
                  className={infoName}
                  match="target_audience"
                  onClick={() => setInfoName("target_audience")}
                >
                  <MentorOptionText>Target Audience</MentorOptionText>
                  <MentorOptionLine
                    className={infoName}
                    match="target_audience"
                  />
                </MentorOptionSelect>
                <MentorOptionSelect
                  className={infoName}
                  match="additional_description"
                  onClick={() => setInfoName("additional_description")}
                >
                  <MentorOptionText>Additional description</MentorOptionText>
                  <MentorOptionLine
                    className={infoName}
                    match="additional_description"
                  />
                </MentorOptionSelect>
              </MentorOptions>
              <MentorInvisibleLine />
              <div style={{ marginBottom: "5vh" }}>{renderOptions()}</div>
              {courseSections &&
              Array.isArray(courseSections) &&
              courseSections.length > 0
                ? courseSections.map((section, index) => {
                    const { course_section_name, lessons } = section || {};
                    return (
                      <Collapsible
                        key={index}
                        trigger={
                          <CollapsibleTrigger>
                            {course_section_name
                              ? course_section_name.charAt(0).toUpperCase() +
                                course_section_name.slice(1)
                              : ""}
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <div style={{ fontSize: "13px" }}>{`${
                                lessons && lessons.length
                              } ${
                                lessons.length > 1 ? "lessons" : "lesson"
                              }`}</div>
                              <img
                                src={dropdown_green_icon}
                                alt="dropdown_green_icon"
                                width={15}
                                height={10}
                              />
                            </div>
                          </CollapsibleTrigger>
                        }
                      >
                        <CollapsibleContent>
                          <div
                            style={{
                              display: "flex",
                              gap: "100px",
                              marginBottom: "10px",
                              flexWrap: "wrap",
                            }}
                          >
                            {lessons &&
                            Array.isArray(lessons) &&
                            lessons.length > 0
                              ? lessons.map((eachLesson, idx) => {
                                  return (
                                    <CourseRecapDetailCoursePage
                                      eachLesson={eachLesson}
                                      key={idx}
                                      isCourseDetailRecap={true}
                                    />
                                  );
                                })
                              : null}
                          </div>
                        </CollapsibleContent>
                      </Collapsible>
                    );
                  })
                : null}
              <Row
                style={{
                  marginTop: "2em",
                  marginBottom: "2em",
                  backgroundColor: "#f3f3f3",
                }}
              >
                <Col style={{ margin: "1.5em" }}>
                  <TextTitle>
                    Students who have added to their course list
                  </TextTitle>
                  {paginatedData &&
                  Array.isArray(paginatedData) &&
                  paginatedData.length > 0 ? (
                    <Pagination
                      handlePageClick={handlePageClick}
                      pageCount={pageCount}
                      itemOffset={itemOffset + 1}
                      endOffset={itemOffset + perPage}
                      totalItems={initialDataState.length}
                    >
                      <TableInternalComponent
                        table_data={paginatedData}
                        topics={[]}
                        onClickRow={handleOnClickRow}
                        table_name="studentAddedCourseToTheirCourseList"
                      />
                    </Pagination>
                  ) : (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <img
                          src={empty_session_2_icon}
                          alt="empty_session_2_icon"
                        />
                        <h4 style={{ fontSize: "22px" }}>
                          No student were added this course yet
                        </h4>

                        {/* <p style={{ fontSize: "18px" }}>
                          Only completed booking session will appear here
                        </p> */}
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </>
          ) : null}
        </InnerContainer>
      </OuterContainer>
    </div>
  );
};

export default InternalCourseDetail;

const OuterContainer = styled.div`
  padding-left: 350px;
  text-align: left;
`;

const InnerContainer = styled.div`
  padding: 130px 3vw 30px 3vw;
`;

const PageTitle = styled.h1`
  font-size: 36px;
  color: #00bb99;
`;

const Label = styled.h5`
  font-size: 14px;
  color: #00bb99;
`;

const Text = styled.p`
  font-size: 18px;
  color: black;
`;

const BackNavigation = styled.p`
  padding: 0;
  font-size: 16px;
  color: #000000;
  cursor: pointer;
`;

const CollapsibleTrigger = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f3f3f3;
  font-size: 20px;
  padding: 12px 30px;
  font-weight: bold;
  color: #007c7c;
  margin: 5px -30px;
  user-select: none;
  :hover {
    background: #f3f3f3;
    opacity: 0.8;
  }
`;

const CollapsibleContent = styled.div`
  padding: 10px 0;
`;

const TextTitle = styled.div`
  font-size: 25px;
  color: black;
  font-weight: bold;
`;

const MentorOptions = styled.div`
  display: flex;
  flex-wrap: nowrap;
  min-width: 900px;
  font-weight: 500;
  color: #002b73;
`;

const MentorOptionSelect = styled.div`
  margin-top: 5vh;
  margin-bottom: 1vh;
  width: 33.3%;
  color: ${({ className, match }) =>
    className === match ? "#007c7c" : "#C4C4C4"};
  font-weight: ${({ className, match }) =>
    className === match ? "bold" : "500"};
  /* flex-grow: 1; // Important */
`;

const MentorOptionLine = styled.hr`
  opacity: ${({ className, match }) => (className === match ? 1 : 0)};
  padding: 2px;
  margin-top: 0.5em;
`;

const MentorOptionText = styled.p`
  text-align: center;
  padding: 0 1em 0;
  margin-bottom: 0;
  cursor: pointer;
  &:hover {
    color: #007c7c;
    font-weight: ${({ className, match }) =>
      className === match ? "bold" : "500"};
  }

  &:hover + ${MentorOptionLine} {
    opacity: 1;
    color: #007c7c;
    transition: 0.2s;
  }
`;

const MentorInvisibleLine = styled.hr`
  opacity: 0.2;
  margin-top: -29px;
  padding: 2px;
  min-width: 900px;
`;

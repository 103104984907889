import React from "react";
import ReactPaginate from "react-paginate";
import styled from "styled-components";

// Images & Icons
import arrow_left from "../../assets/icons/arrow_left.svg";
import arrow_right from "../../assets/icons/arrow_right.svg";

function Pagination({
  children,
  pageCount,
  handlePageClick,
  itemOffset,
  endOffset,
  totalItems,
  smallScreen,
}) {
  let currentPage = Math.ceil((itemOffset - 1) / 15) + 1;
  return (
    <div>
      {children}
      <PaginationContainer>
        <PaginationInfo smallScreen={smallScreen}>
          Showing {itemOffset}-{endOffset > totalItems ? totalItems : endOffset}{" "}
          of {totalItems}
        </PaginationInfo>
        {pageCount && totalItems && totalItems > 15 && pageCount < 2 ? null : (
          <ReactPaginate
            previousLabel={
              currentPage && currentPage !== 1 ? (
                <img
                  src={arrow_left}
                  alt="arrow_left"
                  height={15}
                  width={15}
                  style={{ objectFit: "scale-down" }}
                />
              ) : null
            }
            nextLabel={
              currentPage && currentPage !== pageCount ? (
                <img
                  src={arrow_right}
                  alt="arrow_right"
                  height={15}
                  width={15}
                  style={{ objectFit: "scale-down" }}
                />
              ) : null
            }
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        )}
      </PaginationContainer>
    </div>
  );
}

export default Pagination;

const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .pagination {
    margin: 15px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    outline: none;
    font-size: 12px;
  }
  .pagination > .active > a {
    color: black;
  }
  .pagination > li > a {
    padding: 5px 5px;
    margin: 0px 5px;
    outline: none;
    cursor: pointer;
  }
  .pagination > .active > a,
  .pagination > .active > span,
  .pagination > .active > a:hover,
  .pagination > .active > span:hover,
  .pagination > .active > a:focus,
  .pagination > .active > span:focus {
    color: black;
    outline: none;
  }
  .pagination > li > a,
  .pagination > li > span {
    color: #aaaaaa;
  }
  .pagination > li:first-child > a,
  .pagination > li:first-child > span,
  .pagination > li:last-child > a,
  .pagination > li:last-child > span {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .previous,
  .next {
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 1px solid black;
    border-radius: 50%;
    color: black;
    img {
      filter: grayscale(0) invert(0);
      z-index: 1000;
      width: 30px;
      height: 30px;
    }
    margin: 0 16px;
    :hover {
      background-color: black;
      border-radius: 50%;
      img {
        filter: grayscale(100%) invert(100%);
        z-index: 1000;
        width: 30px;
        height: 30px;
      }
    }
  }
`;

const PaginationInfo = styled.div`
  position: absolute;
  left: 0;
  font-size: "12px";
  font-weight: 500;
  color: #aaaaaa;
  visibility: ${({ smallScreen }) => (smallScreen === true ? "hidden" : "")};
`;

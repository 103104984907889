import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import styled from "styled-components";

// react bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Date fns
import { format } from "date-fns";

// Apis
import { getAllTopics, getDetailTopic } from "../apis/topics";

// Images and Icons
import arrow_left_icon_dark from "../assets/icons/arrow_left_icon_dark.png";

// Components
import HeaderComponent from "../components/HeaderComponent";
import SidebarInteralComponent from "../components/SidebarInternalComponent";
import SpinnerComponent from "../components/SpinnerComponent";
import Pagination from "../components/layout/Pagination";
import TableInternalComponent from "../components/TableInternalComponent";

const InternalTopicDetail = () => {
  const navigate = useNavigate();
  const { topic_id } = useParams();

  const [isLoading, setLoading] = useState(false);
  const [topics, setTopics] = useState([]);
  const [topicDetail, setTopicDetail] = useState(null);
  const [mentors, setMentors] = useState([]);

  const [data, setData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const [perPage, setPerPage] = useState(15);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  const initialDataState =
    mentors && mentors.length > 0
      ? mentors
          .map(
            ({
              id,
              first_name,
              last_name,
              phone_number,
              mentor_status,
              createdAt,
              mentor_id,
              email,
              topics,
            }) => {
              const mentor_name = first_name + " " + last_name;
              const date = new Date(createdAt).toLocaleString();
              return {
                id,
                mentor_id,
                mentor_name,
                email_address: email,
                phone_number,
                mentor_status,
                registered_date: date,
                topics,
              };
            }
          )
          .sort(function (a, b) {
            return new Date(b.registered_date) - new Date(a.registered_date);
          })
      : [];

  // Handle back button
  function backToTopics() {
    return navigate(`/internal/topics`);
  }

  const handleFetchDetailInternalTopic = async (topic_id) => {
    setLoading(true);
    const result = await getDetailTopic(topic_id);
    if (result && result.data) {
      const { data } = result || {};
      setMentors(data.mentors);
      setTopicDetail(data);
    }
    setLoading(false);
  };

  const handleFetchTopics = async () => {
    setLoading(true);
    const result = await getAllTopics();
    if (result && result.data) {
      let finalTopics = result.data;
      setTopics(finalTopics);
    }
    setLoading(false);
  };

  // Pagination - 1
  function handlePageClick(e) {
    const newOffset = (e.selected * perPage) % data.length;
    setItemOffset(newOffset);
  }

  // Change router to view mentor details
  function handleViewDetails(d) {
    navigate(`/internal/mentors/${d["id"]}`);
  }

  const handleFormatFirstFetchPaginatedData = (mentors) => {
    let initialDataState = mentors
      .map(
        ({
          id,
          first_name,
          last_name,
          phone_number,
          mentor_status,
          createdAt,
          mentor_id,
          email,
        }) => {
          const mentor_name = first_name + " " + last_name;
          const date = new Date(createdAt).toLocaleString();
          return {
            id,
            mentor_id,
            mentor_name,
            email_address: email,
            phone_number,
            mentor_status,
            registered_date: date,
          };
        }
      )
      .sort(function (a, b) {
        return new Date(b.registered_date) - new Date(a.registered_date);
      });
    setData(initialDataState);
    const endOffset = itemOffset + perPage;
    setPaginatedData(initialDataState.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / perPage));
  };

  useEffect(() => {
    if (topic_id) {
      handleFetchDetailInternalTopic(topic_id);
      handleFetchTopics();
    }
  }, [topic_id]);

  useEffect(() => {
    if (mentors && Array.isArray(mentors) && mentors.length > 0) {
      handleFormatFirstFetchPaginatedData(mentors);
    }
  }, [mentors]);

  return (
    <div>
      <HeaderComponent />
      <SidebarInteralComponent />
      {topicDetail ? (
        <OuterContainer>
          <SpinnerComponent isShown={isLoading} right="20px" top="20px" />
          <InnerContainer>
            <Row>
              <BackNavigation onClick={backToTopics}>
                <img
                  src={arrow_left_icon_dark}
                  alt="arrow_left_icon_dark"
                  height={15}
                  width={15}
                  style={{
                    objectFit: "scale-down",
                    objectPosition: "left bottom",
                  }}
                />
                {`    Back`}
              </BackNavigation>
            </Row>
            <Row style={{ marginBottom: 20 }}>
              <Col>
                <PageTitle>Coaching Topic Details</PageTitle>
              </Col>
            </Row>
            <Row>
              <Col>
                <UserInfoContainer>
                  <UserInfo>
                    <LabelUser>Topic title</LabelUser>
                    <TextUser>
                      {topicDetail && topicDetail.title
                        ? topicDetail.title
                        : "Not available"}
                    </TextUser>
                    <LabelUser>Description</LabelUser>
                    <TextUser>
                      {topicDetail && topicDetail.description
                        ? topicDetail.description
                        : "Not available"}
                    </TextUser>
                    <LabelUser>Created on</LabelUser>
                    <TextUser>
                      {topicDetail && topicDetail.createdAt
                        ? format(
                            new Date(topicDetail.createdAt),
                            "dd/LL/yy HH:mm"
                          )
                        : "-"}
                    </TextUser>
                  </UserInfo>
                </UserInfoContainer>
                {mentors && Array.isArray(mentors) && mentors.length > 0 ? (
                  <>
                    <LabelMentorAssignedTable>
                      Mentor assigned to this topic
                    </LabelMentorAssignedTable>
                    <Pagination
                      handlePageClick={handlePageClick}
                      pageCount={pageCount}
                      itemOffset={itemOffset + 1}
                      endOffset={itemOffset + perPage}
                      totalItems={initialDataState.length}
                    >
                      <TableInternalComponent
                        table_data={paginatedData}
                        topics={topics}
                        table_name="Mentors"
                        onClickRow={handleViewDetails}
                      />
                    </Pagination>
                  </>
                ) : null}
              </Col>
            </Row>
          </InnerContainer>
        </OuterContainer>
      ) : null}
    </div>
  );
};

export default InternalTopicDetail;

const OuterContainer = styled.div`
  padding-left: 350px;
  text-align: left;
`;

const InnerContainer = styled.div`
  padding: 130px 3vw 30px 3vw;
`;

const PageTitle = styled.h1`
  font-size: 36px;
  color: #00bb99;
`;

const LabelUser = styled.h5`
  font-size: 14px;
  color: #00bb99;
  margin-left: 0.5em;
  margin-top: 1em;
`;

const TextUser = styled.p`
  font-size: 18px;
  color: black;
  margin-left: 0.5em;
`;

const UserInfoContainer = styled.div`
  min-width: 420px;
  max-width: 550px;
  background: #fff2de;
  border-radius: 10px;
  margin-bottom: 1em;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 200px;
`;

const BackNavigation = styled.p`
  padding: 0;
  font-size: 16px;
  color: #000000;
  cursor: pointer;
`;

const LabelMentorAssignedTable = styled.h2`
  font-size: 25px;
  color: #00bb99;
  margin-top: 3em;
`;
